import cls from 'classnames';
import { FC, MouseEvent } from 'react';

import { Link } from '@/types/views/generic';

import CustomLink from '../custom-link';

type IconProps = {
  element: JSX.Element;
  isCircle?: boolean;
  isButton?: boolean;
  title?: string;
  link?: Link;
  className?: string;
  onClick?: (e: MouseEvent) => void;
};

const circleClasses = 'flex justify-center items-center rounded-full';

const Icon: FC<IconProps> = ({
  isCircle = false,
  isButton = false,
  title = '',
  element,
  link,
  className = '',
  onClick,
}) => {
  const classes = isCircle ? cls(className, circleClasses) : className;

  if (isButton) {
    return (
      <button
        title={title}
        className={cls(classes, 'overflow-hidden')}
        onClick={onClick}
      >
        {element}
      </button>
    );
  }

  if (link) {
    return (
      <CustomLink {...link} className={classes} onClick={onClick}>
        {element}
      </CustomLink>
    );
  }

  return (
    <div title={title} className={classes} onClick={onClick}>
      {element}
    </div>
  );
};

export default Icon;
