import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPlayerCurrentAudio } from '@/store/slices/player';

import AudioPlayPauseButton from './audio-play-pause-button';
import RadioPlayPauseButton from './radio-play-pause-button';

interface AudioOrRadioPlayPauseButtonProps {
  isActive?: boolean;
  playIcon?: JSX.Element;
  pauseIcon?: JSX.Element;
  stopPropagation?: boolean;
  channelId?: string;
  className?: string;
}

const AudioOrRadioPlayPauseButton: FC<AudioOrRadioPlayPauseButtonProps> = ({
  isActive,
  playIcon,
  pauseIcon,
  className,
  stopPropagation,
  channelId,
}) => {
  const currentAudio = useSelector(selectPlayerCurrentAudio);

  if (currentAudio)
    return (
      <AudioPlayPauseButton
        isActive={isActive}
        stopPropagation={stopPropagation}
        className={className}
        pauseIcon={pauseIcon}
        playIcon={playIcon}
      />
    );

  return (
    <RadioPlayPauseButton
      isActive={isActive}
      stopPropagation={stopPropagation}
      channelId={channelId}
      className={className}
      pauseIcon={pauseIcon}
      playIcon={playIcon}
    />
  );
};

export default AudioOrRadioPlayPauseButton;
