import React, { FC } from 'react';
import { colors } from 'tailwind/theme';

import Back10Icon from '@/components/icons/back-10-icon';
import Back30Icon from '@/components/icons/back-30-icon';
import Forward10Icon from '@/components/icons/forward-10-icon';
import Forward30Icon from '@/components/icons/forward-30-icon';
import PauseIconThin from '@/components/icons/pause-icon-thin';
import PlayIconThin from '@/components/icons/play-icon-thin';
import AudioPlayPauseButton from '@/components/play-pause-button/audio-play-pause-button';
import SkipButton from '@/components/skip-button';

const playPauseIconClasses = 'w-full h-full';
const iconClasses = 'hover:fill-red';

const AudioActionButtons: FC = () => (
  <div className="flex justify-center items-center shrink-0">
    <SkipButton icon={<Back30Icon className={iconClasses} />} step={-30} />
    <SkipButton icon={<Back10Icon className={iconClasses} />} step={-10} />

    <AudioPlayPauseButton
      isActive
      playIcon={
        <PlayIconThin
          className={playPauseIconClasses}
          backgroundColor={colors.red.medium}
          strokeWidth={0}
        />
      }
      pauseIcon={
        <PauseIconThin
          className={playPauseIconClasses}
          backgroundColor={colors.black.DEFAULT}
          strokeWidth={0}
        />
      }
      className="w-16 h-16 mx-4 overflow-hidden"
      stopPropagation
    />
    <SkipButton icon={<Forward10Icon className={iconClasses} />} step={10} />
    <SkipButton icon={<Forward30Icon className={iconClasses} />} step={30} />
  </div>
);

export default AudioActionButtons;
