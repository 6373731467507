import { Lottery2Api } from '@/types/apis/lottery2';
import {
  LotteryV2,
  LotteryV2Field,
  LotteryV2FieldSettings,
  LotteryV2Pot,
} from '@/types/views/generic';

import fetchLotteryV2ById from './fetch-lottery-v2-by-id';

const mapToSettings = ({
  options,
}: Lottery2Api.FieldSettings): LotteryV2FieldSettings => ({
  options: options.map(({ title, value }) => ({
    title,
    value,
  })),
});

const mapToField = (field: Lottery2Api.Field): LotteryV2Field => {
  const {
    id,
    name,
    display_name: displayName,
    type,
    settings,
    pot_id: potId,
  } = field;

  return {
    id,
    name,
    displayName,
    type,
    settings: mapToSettings(settings),
    potId,
  };
};

const mapToPot = (pot: Lottery2Api.Pot): LotteryV2Pot => {
  const {
    id,
    lottery_id: lotteryId,
    name,
    type,
    multiple_participations: multipleParticipations,
    custom_field_position_top: customFieldPositionTop,
    customfields: customFields,
  } = pot;

  return {
    potId: id,
    lotteryId,
    name,
    type,
    multipleParticipations,
    customFieldPositionTop,
    customFields: customFields.map(mapToField),
  };
};

const mapToLotteryV2 = (
  lottery: Lottery2Api.Lottery,
): Omit<LotteryV2, 'title'> | null => {
  const {
    id,
    name,
    active,
    started_at: startedAt,
    ended_at: endedAt,
    default_pot: defaultPot,
  } = lottery;

  if (!defaultPot) {
    return null;
  }

  return {
    id,
    name,
    active,
    startedAt,
    endedAt,
    defaultPot: mapToPot(defaultPot),
  };
};

const getLotteryV2 = async (
  lotteryId: string,
): Promise<Omit<LotteryV2, 'title'> | null> => {
  const { data, error } = await fetchLotteryV2ById(lotteryId);

  if (error || !data) {
    return null;
  }

  return mapToLotteryV2(data);
};

export default getLotteryV2;
