import React, { FC } from 'react';

import Image from '@/components/image';
import { ThemeSettings } from '@/types/views/generic';

interface ThemeLogoProps {
  isMobile: boolean;
  themeSettings: ThemeSettings;
}

const ThemeLogo: FC<ThemeLogoProps> = ({ isMobile, themeSettings }) => {
  const logoType = isMobile ? themeSettings.logoMobile : themeSettings.logo;

  if (!logoType) return null;

  return (
    <Image
      className={
        isMobile
          ? '2xl:hidden xl:tels:hidden'
          : 'hidden 2xl:block xl:tels:block'
      }
      src={logoType.src}
      useIntrinsicSize
      width={logoType.width}
      height={logoType.height}
      alt={`${themeSettings.title || ''} - Logo`}
    />
  );
};

export default ThemeLogo;
