import cls from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ImageFlipper from '@/components/image-flipper/image-flipper';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import getChannelUrl from '@/lib/get-channel-url';
import useShowData from '@/lib/hooks/use-show-data';
import { mapUrlToLink } from '@/middleware/mappers/link';
import {
  selectPlayerIsPaused,
  selectPlayerIsPlaying,
  selectPlayerMetadata,
} from '@/store/slices/player';
import { Channel, ChannelGroupType } from '@/types/views/generic';

import RadioActionButtons from './action-buttons/RadioActionButtons';
import Countdowns from './countdowns';
import Player from './player';
import PlayerBgImage from './player-bg-image/player-bg-image';

interface ChannelPlayerProps {
  onClose: () => void;
  currentChannel: Channel;
  currentChannelGroupType: ChannelGroupType;
}

const ChannelPlayer = ({
  onClose,
  currentChannel,
  currentChannelGroupType,
}: ChannelPlayerProps) => {
  const { show, fetchCurrentShow } = useShowData(currentChannel.id);

  useEffect(() => {
    fetchCurrentShow();
  }, [currentChannel, fetchCurrentShow]);

  const playlistUrl = getChannelUrl(
    currentChannel,
    currentChannelGroupType,
    true,
  );

  const metadata = useSelector(selectPlayerMetadata);
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const isPaused = useSelector(selectPlayerIsPaused);

  const isStation = currentChannelGroupType === ChannelGroupType.Station;

  const shareUrl = `${getBaseUrlIfBrowser()}/${
    isStation
      ? `stations/${currentChannel.slug}`
      : `channels/${currentChannel.id}`
  }`;

  return (
    <Player
      onClose={onClose}
      isPlaying={isPlaying}
      isPaused={isPaused}
      primaryPlayingTitle={metadata?.title}
      secondaryPlayingTitle={metadata?.artist}
      fullShareUrl={shareUrl}
      playerBgImage={
        <PlayerBgImage
          isStation={isStation}
          logoUrl={currentChannel.logoUrl}
          bgMobile={currentChannel.bgMobile}
        />
      }
      title={show?.title || currentChannel?.name}
      cover={
        <div
          className={cls(
            'overflow-hidden z-0',
            isStation ? 'rounded-full' : 'rounded-2',
          )}
        >
          <ImageFlipper
            coverUrl={metadata?.coverUrl}
            isActive={isPlaying}
            logoUrl={currentChannel.logoUrl}
          />
        </div>
      }
      countdowns={
        <Countdowns
          className="w-full md:px-4 mb-4"
          frequency={currentChannel.frequency}
          isLive
        />
      }
      actionButton={
        <RadioActionButtons
          displayedStationId={currentChannel.id}
          playlistLink={mapUrlToLink(playlistUrl)}
          onPlaylistClick={onClose}
        />
      }
      moderatorTags={show?.moderatorTags}
    />
  );
};

export default ChannelPlayer;
