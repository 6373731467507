import AudioIcon from '@/components/icons/audio-icon';
import PlayIcon from '@/components/icons/play-icon';
import Image from '@/components/image';
import Marquee from '@/components/marquee';
import RadioPlayPauseButton from '@/components/play-pause-button/radio-play-pause-button';

import { channelList as styles } from './styles';

interface SwitchProps {
  textPrimary: string;
  isActive: boolean;
  textSecondary?: string;
  imageUrl?: string;
  imageOverride?: React.ReactElement;
  onClick: () => void;
  channelId?: string;
  hasVersionXL?: boolean;
  hasRoundedLogo?: boolean;
}

const Switch = ({
  textPrimary,
  isActive,
  textSecondary,
  imageUrl,
  imageOverride,
  onClick,
  channelId,
  hasVersionXL = false,
  hasRoundedLogo = false,
}: SwitchProps) => {
  let finalImage: JSX.Element;

  if (imageOverride) {
    finalImage = imageOverride;
  } else {
    finalImage = imageUrl ? (
      <Image src={imageUrl} basicLoader />
    ) : (
      <span className={styles.logoPlaceholder} />
    );
  }

  return (
    <div className={styles.wrapper(hasVersionXL)} onClick={onClick}>
      <div className={styles.logoWrapper(hasRoundedLogo, hasVersionXL)}>
        <div className={styles.imageOverlay(isActive)} />

        <div className={styles.image}>{finalImage}</div>

        <RadioPlayPauseButton
          isActive={isActive}
          className={styles.playPauseButton(isActive, hasVersionXL)}
          channelId={channelId}
          playIcon={
            isActive ? (
              <AudioIcon className={styles.icon} />
            ) : (
              <PlayIcon className={styles.icon} />
            )
          }
          pauseIcon={<AudioIcon className={styles.icon} />}
          stopPropagation
        />
      </div>

      <div className={styles.textWrapper(hasVersionXL)}>
        <Marquee className={styles.textPrimary} gradient={false}>
          <span>{textPrimary}</span>
        </Marquee>
        <span className={styles.textSecondary(hasVersionXL)}>
          {textSecondary}
        </span>
      </div>
    </div>
  );
};

export default Switch;
