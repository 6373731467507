import { css } from '@emotion/css';
import cls from 'classnames';
import React, { useEffect, useState } from 'react';

import Image from '@/components/image';

const flipWrapper = css`
  perspective: 1000px;

  &.flipped .flip-inner {
    transform: rotateY(180deg);
  }

  .flip-inner {
    transform-style: preserve-3d;
  }

  .flip-front,
  .flip-back {
    backface-visibility: hidden;
  }

  .flip-back {
    transform: rotateY(180deg);
  }
`;

interface ImageFlipperProps {
  isActive?: boolean;
  isLoading?: boolean;
  coverUrl?: string;
  logoUrl?: string;
}

const ImageFlipper = ({
  isActive,
  isLoading,
  coverUrl,
  logoUrl,
}: ImageFlipperProps) => {
  const [showSongCover, setShowSongCover] = useState(false);

  // Infinite flip for song cover every 5 sec for active slide
  useEffect(() => {
    let flipIntervalId: ReturnType<typeof setInterval>;

    if (!isLoading && coverUrl) {
      flipIntervalId = setInterval(() => {
        setShowSongCover((prevState) => !prevState);
      }, 8000);
    } else {
      setShowSongCover(false);
    }

    return () => flipIntervalId && clearInterval(flipIntervalId);
  }, [isActive, coverUrl, isLoading]);

  return (
    <div
      className={cls(
        'relative',
        flipWrapper,
        isActive && showSongCover && 'flipped',
      )}
    >
      <div className="flip-inner aspect aspect-1-1 relative transition-transform duration-300">
        <div className="flip-front absolute inset-0">
          {logoUrl ? (
            <Image
              src={logoUrl}
              objectFit="cover"
              className="aspect aspect-1-1"
            />
          ) : (
            <div className="aspect aspect-1-1 bg-black-light" />
          )}
        </div>

        {isActive && coverUrl && (
          <div className="flip-back absolute inset-0">
            <Image
              src={coverUrl}
              objectFit="cover"
              className="aspect aspect-1-1"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageFlipper;
