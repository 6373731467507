import { css } from '@emotion/css';
import cls from 'classnames';

import { screens } from '@/../tailwind/theme';

const centerContainer = css`
  // TODO: Refactor this code
  @media (min-width: ${screens.md}) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

export const styles = {
  contentContainer: cls(
    'content-box lg:px-6 xl:content-box flex flex-col lg:flex-row mt-8 mb-4 lg:justify-between tels:pt-28 lg:tels:pt-0',
    centerContainer,
  ),
  leftSideWrapper: 'flex flex-col relative',
  cardNavigation: 'lg:pl-8 pl-0 lg:ml-auto',
  mainNavigation: 'mb-8 mt-6 lg:mt-8',
  smallLabelNavigation: 'mb-4 md:mb-6',
  divider:
    'w-full h-px bg-gray-1 md:bg-gray-3 teair:bg-air-lila-light tesn:bg-esn-secondary-light tesn:bg-opacity-50 teds:bg-eds-gray-light',
  socialNavWrapper: 'text-black mb-6',
  socialNavItem: 'bg-white hover:bg-red-medium',
};
