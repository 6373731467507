import { useEffect, useState } from 'react';

import { debounce } from '../utils';

const useDeviceType = () => {
  const mediaMatches = window.matchMedia('(pointer: coarse)');

  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(
    typeof window !== 'undefined' ? mediaMatches.matches : false,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const callback = () =>
        debounce(() => {
          setIsTouchDevice(mediaMatches.matches);
        });

      mediaMatches.addEventListener('change', callback);

      return () => {
        mediaMatches.removeEventListener('change', callback);
      };
    }

    return undefined;
  }, [mediaMatches]);

  return { isTouchDevice };
};

export default useDeviceType;
