import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';

import getCurrentShow from '@/lib/get-current-show';
import { ShowEntry } from '@/types/views/generic';

type ShowData = {
  show: ShowState['data'];
  fetchCurrentShow: () => void;
  isLoading: ShowState['isLoading'];
};

interface ShowState {
  data: ShowEntry | null;
  isLoading: boolean;
}

const CALLS_COUNTER_THRESHOLD = 2;
const REFETCH_INTERVAL = 120000; // in ms

const useShowData = (stationId: string): ShowData => {
  const [state, dispatch] = useReducer<Reducer<ShowState, Partial<ShowState>>>(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { isLoading: true, data: null },
  );

  const [callsCounter, setCallsCounter] = useState(0);

  const fetchCurrentShow = useCallback(async () => {
    dispatch({
      isLoading: true,
    });

    const currentShow = await getCurrentShow(stationId);

    setCallsCounter((prevState) => prevState + 1);

    dispatch({
      isLoading: false,
      data: currentShow,
    });

    return currentShow;
  }, [stationId]);

  const shouldRetryFetch = !state.data && !state.isLoading;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const retryFetch = async () => {
      if (callsCounter < CALLS_COUNTER_THRESHOLD) {
        fetchCurrentShow();
      } else {
        timeout = setTimeout(() => {
          fetchCurrentShow();
        }, REFETCH_INTERVAL);
      }
    };

    if (shouldRetryFetch) {
      retryFetch();
    }

    return () => clearTimeout(timeout);
  }, [callsCounter, fetchCurrentShow, shouldRetryFetch]);

  useEffect(() => {
    fetchCurrentShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCallsCounter(0);
  }, [stationId]);

  return {
    show: state.data,
    fetchCurrentShow,
    isLoading: state.isLoading,
  };
};

export default useShowData;
