import cls from 'classnames';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CrossIcon from '@/components/icons/icon-cross';
import FirstStep from '@/components/newsletter/components/first-step';
import SecondStep from '@/components/newsletter/components/second-step';
import SuccessBlock from '@/components/newsletter/components/success-block';
import ThirdStep from '@/components/newsletter/components/third-step';
import {
  selectIsNewsletterOpen,
  setIsNewsletterOpen,
} from '@/store/slices/global';
import { selectIsUserLoggedIn, selectUserProfile } from '@/store/slices/user';
import { ImageAsset, NewsletterFormStep } from '@/types/views/generic';

import styles from './styles';

interface NewsletterProps {
  isModal?: boolean;
  newsletterMobileImage?: ImageAsset;
  newsletterDesktopImage?: ImageAsset;
  newsletterSuccessImage?: ImageAsset;
  newsletterErrorImage?: ImageAsset;
  deactivateAdSlots?: boolean;
}

type FormInputData = {
  email: string;
  firstName: string;
  lastName: string;
};

const Newsletter: FC<NewsletterProps> = ({
  isModal = true,
  newsletterMobileImage,
  newsletterDesktopImage,
  newsletterSuccessImage,
  newsletterErrorImage,
  deactivateAdSlots,
}) => {
  const [activeStep, setActiveStep] = useState<number>(
    NewsletterFormStep.First,
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { asPath, replace: routeReplace } = useRouter();

  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const profile = useSelector(selectUserProfile);
  const isNewsletterOpen = useSelector(selectIsNewsletterOpen);

  const toggleNewsletterIsOpen = (state: boolean) =>
    dispatch(setIsNewsletterOpen(state));

  // Added to animate appearance of third step when newsletter is not a modal
  const [isAnimatedThirdStep, setIsAnimatedThirdStep] =
    useState<boolean>(false);

  const isFirstStepActive = activeStep === NewsletterFormStep.First;
  const isSecondStepActive = activeStep === NewsletterFormStep.Second;
  const isThirdStepActive = activeStep === NewsletterFormStep.Third;

  useEffect(() => {
    if (!isModal) {
      requestAnimationFrame(() => setIsAnimatedThirdStep(isThirdStepActive));
    }
  }, [isThirdStepActive, isModal]);

  useEffect(() => {
    if (isNewsletterOpen) {
      setIsAnimated(true);
    }
  }, [isNewsletterOpen]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isSubmitting },
    watch,
  } = useForm<FormInputData>({
    mode: 'all',
    defaultValues: {
      email: isUserLoggedIn ? profile?.email : '',
    },
  });

  const { firstName, lastName } = watch();

  const closeModal = () => {
    requestAnimationFrame(() => setIsAnimated(false));

    const hashParam = asPath.match(/#([a-zA-Z0-9]+)/gi);

    if (hashParam?.length) {
      const hashParamAction = hashParam[0];
      routeReplace(asPath, asPath.replace(hashParamAction, ''), {
        shallow: true,
      });
    }

    setTimeout(() => {
      toggleNewsletterIsOpen(false);
      setActiveStep(NewsletterFormStep.First);
      reset();
    }, 500);
  };

  const onNextButtonClick = (nextStep: number) => {
    setActiveStep(nextStep);
  };

  const onSaveClick = (nextStep: number) => {
    setHasError(false);
    setIsSuccess(false);

    const submitForm = handleSubmit(async (inputData: FormInputData) => {
      const response = await fetch('/api/newsletter-sendinblue', {
        method: 'post',
        body: JSON.stringify(inputData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setHasError(true);

        const res = await response?.json();

        const isErrorEmail = !!res?.isErrorEmail;

        if (isErrorEmail) {
          setIsEmailError(isErrorEmail);
        }
      } else {
        setIsSuccess(true);
      }

      onNextButtonClick(nextStep);
    });

    return submitForm();
  };

  const renderContent = () => (
    <div className={styles.wrapper(isModal)}>
      {isModal && <div className={styles.backdrop(isAnimated)} />}

      <div className={cls(styles.content, deactivateAdSlots && 'md:ml-auto')}>
        <form
          className={styles.form(isAnimated, isModal)}
          onSubmit={(ev) => ev.preventDefault()}
        >
          {!isModal && isSuccess ? (
            <SuccessBlock newsletterSuccessImage={newsletterSuccessImage} />
          ) : (
            <>
              {isFirstStepActive && (
                <>
                  {isModal && (
                    <div className={styles.closeIcon} onClick={closeModal}>
                      <CrossIcon />
                    </div>
                  )}

                  <FirstStep
                    register={register}
                    errors={errors}
                    buttonDisabled={isSubmitting || !isValid || !!errors.email}
                    onNextButtonClick={() =>
                      onNextButtonClick(NewsletterFormStep.Second)
                    }
                    newsletterMobileImage={newsletterMobileImage}
                    newsletterDesktopImage={newsletterDesktopImage}
                  />
                </>
              )}

              {isSecondStepActive && (
                <SecondStep
                  register={register}
                  errors={errors}
                  buttonDisabled={
                    isSubmitting ||
                    !isValid ||
                    !!errors.firstName ||
                    !!errors.lastName
                  }
                  onPrevButtonClick={() =>
                    setActiveStep(NewsletterFormStep.First)
                  }
                  onNextButtonClick={() =>
                    onSaveClick(NewsletterFormStep.Third)
                  }
                  inputValues={{ firstName, lastName }}
                />
              )}
            </>
          )}

          {isThirdStepActive &&
            (isModal ? (
              <ThirdStep
                onButtonClick={() =>
                  hasError
                    ? setActiveStep(NewsletterFormStep.Second)
                    : closeModal()
                }
                onPrevButtonClick={() =>
                  setActiveStep(NewsletterFormStep.First)
                }
                hasError={hasError}
                emailExists={isEmailError}
                newsletterErrorImage={newsletterErrorImage}
                newsletterSuccessImage={newsletterSuccessImage}
              />
            ) : (
              <div className={styles.wrapper()}>
                <div className={styles.backdrop(isAnimatedThirdStep)} />

                <div className={styles.content}>
                  <div className={styles.form(isAnimatedThirdStep)}>
                    <ThirdStep
                      onButtonClick={() =>
                        setActiveStep(NewsletterFormStep.Second)
                      }
                      onPrevButtonClick={() =>
                        setActiveStep(NewsletterFormStep.First)
                      }
                      hasError={hasError}
                      emailExists={isEmailError}
                      newsletterSuccessImage={newsletterSuccessImage}
                      newsletterErrorImage={newsletterErrorImage}
                    />
                  </div>
                </div>
              </div>
            ))}
        </form>
      </div>
    </div>
  );

  return isModal && typeof document !== 'undefined'
    ? ReactDOM.createPortal(
        renderContent(),
        document.querySelector('#modals') as Element,
      )
    : renderContent();
};

export default Newsletter;
