import cls from 'classnames';

const section =
  'pr-2 md:pr-12 mb-8 md:mb-8 col-span-1 md:col-span-1 2xl:col-span-1';

const styles = {
  wrapper: (hasCustomFooter = false) =>
    cls(
      'w-full bg-red-light mt-4 md:pb-12 teair:bg-air-deepBlue game:bg-black game:mt-0 tlegal:mt-0',
      hasCustomFooter && 'teair:bg-white teair:mt-0',
    ),
  roundedSpace: (hasCustomFooter = false) =>
    cls(
      'h-8 md:h-16 rounded-b-8 md:rounded-b-16 bg-black mb-12 md:mb-24 teone:bg-white game:bg-game-blue-midnight tlegal:bg-white',
      hasCustomFooter ? 'teair:bg-air-deepBlue' : 'teair:bg-white',
    ),
  grid: 'content-box grid grid-rows-auto grid-cols-2 md:grid-cols-4 2xl:grid-cols-3 grid-rows-4 md:grid-rows-2 md:grid-flow-col gap-2 even:mb-12',
  sectionNewsletter: (hasCustomFooter = false) =>
    cls(
      `order-1 md:order-1 col-span-2 md:col-span-2 ${section}`,
      hasCustomFooter && 'teair:text-air-deepBlue',
    ),
  sectionFollow: `order-6 md:order-2 col-span-2 md:col-span-2 ${section} md:pt-6`,
  socialNavWrapper: 'pt-2 text-white teair:text-air-deepBlue',
  socialNavItem: (hasCustomFooter = false) =>
    cls(
      'bg-red hover:bg-red-medium',
      hasCustomFooter
        ? 'teair:bg-air-deepBlue teair:text-white teair:hover:bg-air-lila-light teair:hover:text-air-deepBlue'
        : 'teair:bg-white teair:hover:bg-air-lila-light teair:text-air-deepBlue',
      'tesn:bg-esn-secondary-dark tesn:hover:bg-esn-secondary',
      'teds:bg-eds-black teds:hover:text-eds-black-light',
      'tels:bg-els-blue-grey tels:text-els-white-warm',
      'game:bg-white game:text-black-extra',
    ),
  sectionNav: section,
  sectionRadios: `order-2 md:order-3`,
  sectionUseful: `order-5 md:order-6`,
  sectionApps: `order-4 md:order-4`,
  sectionMedia: `order-3 md:order-5`,
  newsletterDescription: (hasCustomFooter = false) =>
    cls(
      'text-body2 text-red mb-4 tesn:text-esn-secondary-dark teds:text-eds-black tels:text-els-blue-grey game:text-white',
      hasCustomFooter ? 'teair:text-air-deepBlue' : 'teair:text-white',
    ),
  listElement: (hasCustomFooter = false) =>
    cls(
      'text-body2 text-red mb-2 tesn:text-esn-secondary-dark teds:text-eds-black tels:text-els-blue-grey game:text-white',
      hasCustomFooter ? 'teair:text-air-deepBlue' : 'teair:text-white',
    ),
  copyrightText: (hasCustomFooter = false) =>
    cls(
      'text-overline text-red pt-8 md:pt-4 tesn:text-esn-secondary-dark teds:text-eds-black tels:text-els-blue-grey game:text-white',
      hasCustomFooter ? 'teair:text-air-deepBlue' : 'teair:text-white',
    ),
};

export default styles;
