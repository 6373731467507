const styles = {
  wrapper: 'md:grid md:grid-cols-[0.65fr_0.35fr] place-items-center md:gap-10',
  leftSide: `mt-10 xl:mt-0 md:order-1`,
  heading: 'text-red-light text-h4',
  rowGroup: 'md:flex md:mt-6 md:items-start',
  input: 'mt-10 md:mt-0',
  button: `w-full mt-5 md:mt-0 py-3 md:max-w-[218px]`,
  imageContainer: `h-[166px] tesn:h-[213px] md:h-full w-full md:order-2`,
  text: 'text-overline text-gray-2 tesn:text-esn-secondary-light mt-6',
};

export default styles;
