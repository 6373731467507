import * as R from 'ramda';
import React, { forwardRef, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import CrossIcon from '@/components/icons/cross-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useLockBodyScroll from '@/lib/hooks/use-lock-body-scroll';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useVariables from '@/lib/hooks/use-variables';
import {
  selectPlayerChannelGroups,
  selectPlayerCurrentAudio,
  selectPlayerCurrentChannelWithGroup,
  selectPlayerIsPlaying,
} from '@/store/slices/player';
import { ChannelGroupType } from '@/types/views/generic';

import AudioPlayer from '../player/audio-player';
import ChannelPlayer from '../player/channel-player';
import ChannelList from './channel-list';
import styles from './styles';

interface ChannelSwitcherMobileProps {
  onClose: () => void;
}

const ChannelSwitcherMobile = forwardRef<
  HTMLDivElement,
  ChannelSwitcherMobileProps
>(({ onClose }, ref) => {
  const channelGroups = useSelector(selectPlayerChannelGroups);

  const currentChannelWithGroup = useSelector(
    selectPlayerCurrentChannelWithGroup,
  );

  const isPlaying = useSelector(selectPlayerIsPlaying);
  const currentAudio = useSelector(selectPlayerCurrentAudio);

  useLockBodyScroll();

  const variables = useVariables();

  const { isEventTheme } = usePageTheme();

  const channelGroupsWithoutExternal = R.dissoc(
    ChannelGroupType.External,
    channelGroups,
  );

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.closeIcon} onClick={onClose}>
        <CrossIcon />
      </div>
      <div className="p-5">
        <h2 className={styles.headerText}>
          {getVariablePlainText(
            variables[
              isPlaying
                ? 'player-channel-switch-playing-title'
                : 'player-channel-switch-title-not-playing-title'
            ],
          )}
        </h2>

        {currentAudio && (
          <AudioPlayer onClose={onClose} currentAudio={currentAudio} />
        )}

        {currentChannelWithGroup && !currentAudio && (
          <ChannelPlayer
            onClose={onClose}
            currentChannel={currentChannelWithGroup.channel}
            currentChannelGroupType={currentChannelWithGroup.group}
          />
        )}
      </div>
      {Object.entries(channelGroupsWithoutExternal).reduce<ReactNode[]>(
        (acc, [type, channels], i) => [
          ...acc,
          <hr className={styles.divider} key={i} />,
          <ChannelList
            key={type}
            type={type as ChannelGroupType}
            channels={channels}
            isEventTheme={isEventTheme}
            onPlaylistIconClick={onClose}
          />,
        ],
        [],
      )}
    </div>
  );
});

export default ChannelSwitcherMobile;
