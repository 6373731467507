import { FC } from 'react';

interface SpotifyProps extends React.ComponentProps<'iframe'> {
  src: string;
  title: string;
}

const Spotify: FC<SpotifyProps> = ({ src, title, ...restProps }) => (
  <iframe {...restProps} title={title} src={src} />
);

export default Spotify;
