import { css } from '@emotion/css';
import cls from 'classnames';
import React, { FC } from 'react';

import ConsentWrapper from '@/components/one-trust/consent-wrapper';
import { OneTrust } from '@/types/apis/one-trust';
import { EmbedSectionEntry, EmbedType } from '@/types/views/sections';

import Brightcove from './components/brightcove';
import Facebook from './components/facebook';
import Instagram from './components/instagram';
import Soundcloud from './components/soundcloud';
import Spotify from './components/spotify';
import YouTube from './components/youtube/index';

const widthForSquare = css`
  width: 56.25%;
  left: auto;
  right: auto;
`;

const blockTypes = {
  [EmbedType.Brightcove]: Brightcove,
  [EmbedType.Facebook]: Facebook,
  [EmbedType.Instagram]: Instagram,
  [EmbedType.Spotify]: Spotify,
  [EmbedType.Youtube]: YouTube,
  [EmbedType.Soundcloud]: Soundcloud,
};

const Embed: FC<EmbedSectionEntry> = ({
  title,
  url,
  embedType,
  instagramHtml,
  layout,
  deactivateAdSlots,
}) => {
  const Component = blockTypes[embedType];

  if (!Component || (embedType === EmbedType.Instagram && !instagramHtml)) {
    return null;
  }

  const iframeProps: React.ComponentProps<'iframe'> = {
    scrolling: 'no',
    frameBorder: 0,
    allowFullScreen: true,
    allow:
      'autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share',
  };

  const isNotSpotifyAndSoundcloud =
    embedType !== EmbedType.Spotify && embedType !== EmbedType.Soundcloud;

  const isSpotifyOrSoundcloud =
    embedType === EmbedType.Spotify || embedType === EmbedType.Soundcloud;

  const isNotRichTextLayout = layout !== 'rich-text';

  return (
    <div
      className={cls(
        isNotRichTextLayout && 'content-box my-20 md:my-24',
        deactivateAdSlots && 'ml-auto',
      )}
    >
      <div
        className={cls(
          isNotSpotifyAndSoundcloud && isNotRichTextLayout && 'w-5/6 mx-auto',
          'overflow-hidden',
        )}
      >
        <div
          className={cls(
            'flex justify-center aspect',
            embedType !== EmbedType.Instagram &&
              (isSpotifyOrSoundcloud
                ? 'aspect-1-1 sm:aspect-16-9'
                : 'aspect-16-9'),
          )}
        >
          <div
            className={embedType === EmbedType.Facebook ? widthForSquare : ''}
          >
            <ConsentWrapper
              requiredGroups={
                embedType === EmbedType.Youtube
                  ? [OneTrust.ConsentGroups.Targeting]
                  : []
              }
            >
              <Component
                {...iframeProps}
                {...(embedType === EmbedType.Instagram &&
                  instagramHtml && { instagramHtml })}
                className="w-full h-full flex-1"
                title={title}
                src={url}
              />
            </ConsentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Embed;
