const styles = {
  authButtons:
    'flex flex-row justify-start items-center mb-6 lg:mb-8 teair:justify-between',
  underlinedButton:
    'underline text-white font-semibold teair:text-air-deepBlue ml-2',
  loginButtonContent: 'flex items-center',
  profileIcon: 'flex-shrink-0 mr-2',
};

export default styles;
