import DOMPurify from 'dompurify';

const getSvgIconBody = async (url: string) => {
  try {
    const response = await fetch(url);
    const isSvgContentType =
      response.headers.get('content-type') === 'image/svg+xml';

    if (isSvgContentType) {
      const text = await response.text();
      const sanitizedSvg = DOMPurify.sanitize(text, {
        USE_PROFILES: { svg: true, svgFilters: true },
      });

      return sanitizedSvg;
    }

    return '';
  } catch (error) {
    console.error(error);

    return '';
  }
};

export default getSvgIconBody;
