import React, { FC } from 'react';

import TextButton from '@/components/button/text';
import CustomLink from '@/components/custom-link';
import { TextLink } from '@/types/views/generic';

const EmbeddedLink: FC<TextLink> = (props) => (
  <CustomLink {...props}>
    <TextButton theme="light" text={props.text} />
  </CustomLink>
);

export default EmbeddedLink;
