const styles = {
  BOLD: 'font-semibold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  HEADING_1: 'text-h4 mb-8 last:mb-0',
  HEADING_2: 'text-h5 mb-8 last:mb-0',
  HEADING_3: 'text-body2 font-semibold mb-4 last:mb-0',
  HEADING_4: 'text-overline mb-2 pb-1 text-gray-3 last:mb-0',
  PARAGRAPH: 'mb-8 last:mb-0',
  UL_LIST: 'list-disc ml-4 mb-8 last:mb-0',
  OL_LIST: 'list-decimal ml-4 mb-8 last:mb-0',
};

export default styles;
