import cls from 'classnames';

const styles = {
  heading: 'text-h4 text-red-light mb-2',
  text: 'text-overline text-gray-2 mb-8 md:mb-6',
  formGroup: 'md:flex items-start',
  link: 'underline',
  inputWrapper: 'mb-4 md:mr-4',
  input: (hasValue: boolean) => cls(hasValue && 'border-gray-2'),
  button: `w-full mb-6 py-3`,
  btnContainer: 'text-center md:text-left',
  cancelBtn: 'text-body2 text-gray-2 underline',
};

export default styles;
