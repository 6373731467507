import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectPlayerCurrentAudio,
  selectPlayerCurrentChannel,
  selectPlayerMetadata,
} from '@/store/slices/player';

import Image from '../image';

interface PlayerCoverImageProps {
  className?: string;
}
const PlayerCoverImage: FC<PlayerCoverImageProps> = ({ className = '' }) => {
  const metadata = useSelector(selectPlayerMetadata);
  const currentChannel = useSelector(selectPlayerCurrentChannel);
  const currentAudio = useSelector(selectPlayerCurrentAudio);

  const channelImageUrl = useMemo(() => {
    if (currentAudio) {
      if ('imageUrl' in currentAudio) return currentAudio.imageUrl;

      if ('coverUrl' in currentAudio) return currentAudio.coverUrl;
    }

    return currentChannel?.logoUrl;
  }, [currentAudio, currentChannel?.logoUrl]);

  const channelImage = useMemo(() => {
    if (channelImageUrl)
      return (
        <Image
          src={channelImageUrl}
          basicLoader
          className={className}
          objectFit="cover"
        />
      );

    return null;
  }, [channelImageUrl, className]);

  return metadata ? (
    <Image src={metadata.coverUrl} basicLoader />
  ) : (
    channelImage
  );
};

export default PlayerCoverImage;
