import React, { FC } from 'react';

import Icon from '@/components/icon';
import PauseIconThin from '@/components/icons/pause-icon-thin';
import PlayIconThin from '@/components/icons/play-icon-thin';
import PlaylistIcon from '@/components/icons/playlist-icon';
import RadioPlayPauseButton from '@/components/play-pause-button/radio-play-pause-button';
import VolumeButton from '@/components/volume-button';
import { Link } from '@/types/views/generic';

const playPauseIconClasses = 'w-full h-full hover:fill-red';

interface RadioActionButtonsProps {
  displayedStationId?: string;
  onPlaylistClick?: () => void;
  playlistLink: Link;
}

const RadioActionButtons: FC<RadioActionButtonsProps> = ({
  displayedStationId,
  onPlaylistClick,
  playlistLink,
}) => (
  <div className="flex justify-center items-center shrink-0">
    <VolumeButton
      className="w-10 h-10 text-white bg-black"
      iconClassName="w-8 h-8"
      muteClassName="bg-red hover:bg-red-medium"
      volumeClassName="hover:text-red"
      sliderPosition="top"
    />

    <RadioPlayPauseButton
      isActive
      channelId={displayedStationId}
      playIcon={
        <PlayIconThin
          className={playPauseIconClasses}
          backgroundColor="#EB5757"
          strokeWidth={0}
        />
      }
      pauseIcon={
        <PauseIconThin
          className={playPauseIconClasses}
          backgroundColor="#121113"
          strokeWidth={0}
        />
      }
      className="w-16 h-16 mx-4 overflow-hidden"
      stopPropagation
    />

    <Icon
      element={<PlaylistIcon className="w-5 h-5 cursor-pointer" />}
      isCircle
      className="w-10 h-10 bg-black text-white hover:text-red"
      onClick={onPlaylistClick}
      link={playlistLink}
    />
  </div>
);

export default RadioActionButtons;
