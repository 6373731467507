import { ShowEntry } from '@/types/views/generic';

import fetchWithCatch from './fetch-with-catch';

const getCurrentShow = async (stationId: string) => {
  const { data, error } = await fetchWithCatch<ShowEntry>(() =>
    fetch(`/api/station/${stationId}/show`),
  );

  return !data || error ? null : data;
};

export default getCurrentShow;
