import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Image from '@/components/image';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import { mapToSlug } from '@/lib/utils';
import {
  selectAudioPlayerIsPaused,
  selectAudioPlayerIsPlaying,
} from '@/store/slices/player';
import {
  ContentFeedEntrySoundpiece,
  PlaylistItem as PlaylistItemProps,
} from '@/types/views/generic';

import AudioActionButtons from './action-buttons/AudioActionButtons';
import Countdowns from './countdowns';
import Player from './player';
import PlayerBgImage from './player-bg-image/player-bg-image';

interface AudioPlayerProps {
  onClose: () => void;
  currentAudio: ContentFeedEntrySoundpiece | PlaylistItemProps;
}

const AudioPlayer = ({ onClose, currentAudio }: AudioPlayerProps) => {
  const { changeEvent } = useAudioPlayer();

  const isPlaying = useSelector(selectAudioPlayerIsPlaying);
  const isPaused = useSelector(selectAudioPlayerIsPaused);

  const coverUrl = useMemo(() => {
    if ('imageUrl' in currentAudio) return currentAudio.imageUrl;

    return currentAudio.coverUrl;
  }, [currentAudio]);

  const secondaryPlayingTitle = useMemo(() => {
    if ('description' in currentAudio) return currentAudio.description;

    if ('artist' in currentAudio) return currentAudio.artist;

    return undefined;
  }, [currentAudio]);

  const fullShareUrl = useMemo(() => {
    if (!('id' in currentAudio)) return undefined;

    return `${getBaseUrlIfBrowser()}/audios/${currentAudio.id}-${mapToSlug(
      currentAudio.title,
    )}`;
  }, [currentAudio]);

  return (
    <Player
      onClose={onClose}
      isPlaying={isPlaying}
      isPaused={isPaused}
      primaryPlayingTitle={currentAudio.title}
      secondaryPlayingTitle={secondaryPlayingTitle}
      fullShareUrl={fullShareUrl}
      playerBgImage={<PlayerBgImage logoUrl={coverUrl} />}
      cover={
        <div className="overflow-hidden rounded-2">
          <Image
            src={coverUrl}
            objectFit="cover"
            className="aspect aspect-1-1"
          />
        </div>
      }
      countdowns={
        <Countdowns
          className="w-full md:px-4"
          duration={changeEvent?.duration}
          currentTime={changeEvent?.currentTime}
        />
      }
      actionButton={<AudioActionButtons />}
      moderatorTags={'tags' in currentAudio ? currentAudio.tags : undefined}
    />
  );
};

export default AudioPlayer;
