import { FC } from 'react';
import { useSelector } from 'react-redux';

import VideoContainer from '@/components/video-container';
import { selectPlayerIsPlaying } from '@/store/slices/player';
import { VideoEntry } from '@/types/views/sections';

const VideoInline: FC<VideoEntry> = ({ video, isUnMutedSection = false }) => {
  const isRadioPlaying = useSelector(selectPlayerIsPlaying);

  return (
    <VideoContainer
      video={video}
      isInline
      shouldShowVideoInfo={false}
      isUnMutedSection={isUnMutedSection}
      isGlobalRadioPlaying={isRadioPlaying}
    />
  );
};

export default VideoInline;
