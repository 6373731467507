import cls from 'classnames';
import { FC } from 'react';

import Icon from '@/components/icon';
import useAudioPlayer from '@/lib/hooks/use-audio-player';

interface SkipButtonProps {
  icon: JSX.Element;
  step: number;
  className?: string;
}

const SkipButton: FC<SkipButtonProps> = ({ className, icon, step }) => {
  const { skipAudio } = useAudioPlayer();

  const onClick = () => skipAudio(step);

  return (
    <Icon
      isCircle
      isButton
      element={icon}
      onClick={onClick}
      className={cls('m-2', className)}
    />
  );
};

export default SkipButton;
