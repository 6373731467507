import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useState } from 'react';

const useNavbar = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Safari fix for opened mobile menu
      // TODO. Investigate possible use cases of scroll blocking after release
      if (window.getComputedStyle(document.body).overflow === 'hidden') {
        setIsNavbarVisible(true);

        return;
      }

      // Safari top scroll bounce fix
      if (currPos.y >= 0) {
        setIsNavbarVisible(true);
      } else {
        const x = currPos.y > prevPos.y;

        if (x !== isNavbarVisible) setIsNavbarVisible(x);
      }
    },
    [isNavbarVisible],
  );

  return { isNavbarVisible, setIsNavbarVisible };
};

export default useNavbar;
