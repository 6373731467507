import cls from 'classnames';
import React, { FC } from 'react';

import { ArrowRightCircledFilledIcon } from '@/components/icons/arrow-icon';
import { PageTheme } from '@/types/views/generic';

import { textLink as styles } from './styles';

type TextButtonProps = {
  text: string;
  className?: string;
  theme?: PageTheme;
};

const TextButton: FC<TextButtonProps> = ({
  text,
  className,
  theme = 'dark',
}) => (
  <button className={cls(styles.wrapper, className)}>
    <span className={styles.title}>{text}</span>
    <span className={styles.iconWrapper}>
      <ArrowRightCircledFilledIcon
        arrowClassName={styles.circledIconArrow(theme)}
        backgroundClassName={styles.circledIconBackground(theme)}
      />
    </span>
  </button>
);

export default TextButton;
