import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import getVariablePlainText from '@/lib/get-variable-plain-text';
import useAudioPlayer from '@/lib/hooks/use-audio-player';
import usePlayer from '@/lib/hooks/use-player';
import useVariables from '@/lib/hooks/use-variables';
import {
  selectAudioPlayerIsPlaying,
  selectPlayerChannelGroups,
  selectPlayerCurrentAudio,
  selectPlayerCurrentChannelWithGroup,
  selectPlayerIsPlaying,
} from '@/store/slices/player';
import { ChannelGroupType } from '@/types/views/generic';

import AudioPlayer from '../player/audio-player';
import ChannelPlayer from '../player/channel-player';
import ChannelList from './channel-list';
import styles from './styles';

interface ChannelSwitcherDesktopProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChannelSwitcherDesktop = forwardRef<
  HTMLElement,
  ChannelSwitcherDesktopProps
>(({ isOpen, onClose }, ref) => {
  const variables = useVariables();

  // players are need here to ensure
  // that they are mounted and can be played everywhere
  const radioPlayer = usePlayer();
  const audioPlayer = useAudioPlayer();

  const currentChannelWithGroup = useSelector(
    selectPlayerCurrentChannelWithGroup,
  );
  const channelGroups = useSelector(selectPlayerChannelGroups);
  const isRadioPlaying = useSelector(selectPlayerIsPlaying);
  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);
  const currentAudio = useSelector(selectPlayerCurrentAudio);

  const playOrStopStream = () => {
    if (isRadioPlaying) {
      radioPlayer?.stop();
    } else {
      if (isAudioPlaying) {
        audioPlayer.onStop();
      }

      radioPlayer?.play();
    }
  };

  return (
    <section ref={ref} className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.currentRadio}>
          <h2 className={styles.headerText}>
            {getVariablePlainText(
              variables[
                isRadioPlaying || isAudioPlaying
                  ? 'player-channel-switch-playing-title'
                  : 'player-channel-switch-title-not-playing-title'
              ],
            )}
          </h2>

          {currentAudio && (
            <AudioPlayer onClose={onClose} currentAudio={currentAudio} />
          )}

          {currentChannelWithGroup && !currentAudio && (
            <ChannelPlayer
              onClose={onClose}
              currentChannel={currentChannelWithGroup.channel}
              currentChannelGroupType={currentChannelWithGroup.group}
            />
          )}
        </div>

        <div className={styles.channelsWrapperOuter}>
          <h2 className={styles.headerText}>
            {getVariablePlainText(
              variables['channel-switcher-desktop-stations'],
            )}
          </h2>
          <div className={styles.stationChannelsWrapperInner}>
            <ChannelList
              channels={channelGroups.station}
              channelGroupType={ChannelGroupType.Station}
              onClick={playOrStopStream}
            />
          </div>
          <h2 className={styles.headerText}>
            {getVariablePlainText(variables['channel-switcher-desktop-music'])}
          </h2>
          <div className={styles.musicChannelsWrapperInner}>
            <ChannelList
              channels={channelGroups.music}
              channelGroupType={ChannelGroupType.Music}
              onClick={playOrStopStream}
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default ChannelSwitcherDesktop;
