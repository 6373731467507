import fetchWithCatch from '@/lib/fetch-with-catch';
import { Lottery2Api } from '@/types/apis/lottery2';

const fetchLotteryV2ById = (id: string) =>
  fetchWithCatch<Lottery2Api.Lottery>(() =>
    fetch(
      `${process.env.LOTTERY2_API_BASE_URL}/lotteries/${id}?_with=defaultPot.customfields`,
      {
        headers: {
          Authorization: process.env.LOTTERY2_API_TOKEN || '',
        },
      },
    ),
  );

export default fetchLotteryV2ById;
