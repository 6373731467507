import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import throttle from '@/lib/throttle';
import { useStoreDispatch } from '@/store';
import {
  selectPlayerIsMuted,
  selectPlayerVolume,
  setIsMuted,
  setVolume,
} from '@/store/slices/player';

import SliderBox from '../slider-box';

interface SliderProps {
  show?: boolean;
  sliderPosition?: 'bottom' | 'top';
}

const VolumeSlider = ({
  show = false,
  sliderPosition = 'bottom',
}: SliderProps) => {
  const volume = useSelector(selectPlayerVolume);
  const isMuted = useSelector(selectPlayerIsMuted);
  const dispatch = useStoreDispatch();
  const [internalVolume, setInternalVolume] = useState(volume);

  const [isVolumeSliding, setIsVolumeSliding] = useState(false);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isMouseOverSliderRef = useRef(false);

  useEffect(() => {
    setInternalVolume(volume);
  }, [volume]);

  const throttledSetVolume = useMemo(
    () =>
      throttle((value: number) => {
        dispatch(setIsMuted(false));
        dispatch(setVolume(value));
      }, 100),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const calcAndSetVolumeValue = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      const rect = trackRef.current?.getBoundingClientRect();

      if (!rect) return;

      const { top: elTop, height: elHeight } = rect;
      const mouseY = event.clientY;

      if (elHeight === 0) return;

      const mouseYFromElTop = Math.min(Math.max(mouseY - elTop, 0), elHeight);
      const newVolume = Math.round(100 - (mouseYFromElTop / elHeight) * 100);

      setInternalVolume(newVolume);
      throttledSetVolume(newVolume);
    },
    [throttledSetVolume],
  );

  const onMouseDown = (event: React.MouseEvent) => {
    setIsVolumeSliding(true);
    calcAndSetVolumeValue(event);
  };

  const isMouseOverSlider = (event: MouseEvent) =>
    wrapperRef.current?.contains(event.target as Node);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      isMouseOverSliderRef.current = !!isMouseOverSlider(event);

      if (isVolumeSliding) {
        calcAndSetVolumeValue(event);
      }
    },
    [isVolumeSliding, calcAndSetVolumeValue],
  );

  useEffect(() => {
    const onMouseUp = () => {
      setIsVolumeSliding(false);
    };

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  return show || isVolumeSliding ? (
    <div ref={wrapperRef} onMouseDown={onMouseDown} className="px-2 h-full">
      <SliderBox
        trackRef={trackRef}
        value={isMuted ? 0 : internalVolume}
        sliderPosition={sliderPosition}
      />
    </div>
  ) : null;
};

export default VolumeSlider;
