import cls from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

interface SectionProps {
  title: string | ReactNode;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
  shouldUseAlternativeStyles?: boolean;
}

const Section: FC<SectionProps> = ({
  title,
  className = '',
  children = null,
  style,
  shouldUseAlternativeStyles = false,
}) => (
  <section className={className} style={style}>
    <h5
      className={cls(
        'text-body1 font-semibold text-red mb-2 tesn:text-esn-secondary-dark teds:text-eds-black tels:text-els-blue-grey game:text-white',
        shouldUseAlternativeStyles
          ? 'teair:text-air-deepBlue'
          : 'teair:text-white',
      )}
    >
      {title}
    </h5>
    {children}
  </section>
);

export default Section;
