import cls from 'classnames';
import { useRouter } from 'next/router';
import React, { forwardRef, MouseEvent } from 'react';

import Image from '@/components/image';
import SocialNavigation from '@/components/navigation/components/social-navigation';
import useLockBodyScroll from '@/lib/hooks/use-lock-body-scroll';
import { ImageAsset, NavigationEntry } from '@/types/views/generic';

import AuthButtons from './components/auth-buttons';
import CardNavigation from './components/card-navigation';
import EventNav from './components/event-nav';
import MainNavigation from './components/main-navigation';
import SmallLabelNavigation from './components/small-label-navigation';
import { styles } from './styles';

type NavigationProps = {
  navigation: Omit<NavigationEntry, 'fastIconNavigation'>;
  className?: string;
  onNavItemClick?: (e: MouseEvent) => void;
  isEventTheme?: boolean;
  isUserLoggedIn?: boolean;
  navigationLowerImage?: ImageAsset | null;
};

const Navigation = forwardRef<HTMLDivElement, NavigationProps>(
  (
    {
      isUserLoggedIn = false,
      navigation,
      className = '',
      onNavItemClick,
      isEventTheme = false,
      navigationLowerImage,
    },
    ref,
  ) => {
    useLockBodyScroll();
    const { asPath } = useRouter();

    return (
      <div className={className} ref={ref}>
        <div className={styles.contentContainer}>
          <div className={styles.leftSideWrapper}>
            {!isEventTheme ? (
              <>
                <AuthButtons />

                <div className={styles.divider} />

                <MainNavigation
                  navItems={navigation.mainNavItems}
                  currentAsPath={asPath}
                  className={styles.mainNavigation}
                  onNavItemClick={onNavItemClick}
                />

                <SmallLabelNavigation
                  navItems={navigation.smallLabelNavItems}
                  className={styles.smallLabelNavigation}
                  onNavItemClick={onNavItemClick}
                />

                <SocialNavigation
                  socialNavItems={navigation.socialNavItems}
                  wrapperClasses={styles.socialNavWrapper}
                  linkClasses={styles.socialNavItem}
                />
              </>
            ) : (
              <>
                <MainNavigation
                  navItems={navigation?.eventNavItems || []}
                  currentAsPath={asPath}
                  className="mb-4"
                  onNavItemClick={onNavItemClick}
                  isEventTheme
                />

                <div className={cls(styles.divider, 'mb-6')} />

                <AuthButtons />

                {isUserLoggedIn && <EventNav />}

                {navigationLowerImage && (
                  <div className="absolute -bottom-28">
                    <Image
                      src={navigationLowerImage.src}
                      useIntrinsicSize
                      width={navigationLowerImage.width}
                      height={navigationLowerImage.height}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {!isEventTheme && (
            <CardNavigation
              navItems={navigation.cardNavItems}
              className={styles.cardNavigation}
              onNavItemClick={onNavItemClick}
            />
          )}
        </div>
      </div>
    );
  },
);

export default Navigation;
