import cls from 'classnames';
import React, { FC } from 'react';

interface PlayIconThinProps {
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  strokeWidth?: number;
}

const PlayIconThin: FC<PlayIconThinProps> = ({
  className,
  backgroundColor = 'none',
  foregroundColor = 'currentColor',
  strokeWidth = '3',
}) => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    className={cls(className, 'group')}
  >
    <circle
      cx="31"
      cy="31"
      r="29.5"
      className="group-hover:fill-red"
      fill={backgroundColor}
      stroke={foregroundColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M39.5424 32.3511L27.2141 39.5044C26.1679 40.1108 24.8252 39.3765 24.8252 38.1539V23.8474C24.8252 22.6268 26.1659 21.8905 27.2141 22.4989L39.5424 29.6522C39.7804 29.788 39.9783 29.9844 40.1159 30.2214C40.2535 30.4584 40.326 30.7276 40.326 31.0016C40.326 31.2757 40.2535 31.5449 40.1159 31.7819C39.9783 32.0189 39.7804 32.2152 39.5424 32.3511Z"
      fill={foregroundColor}
    />
  </svg>
);

export default PlayIconThin;
