import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import AudioOrRadioPlayPauseButton from '@/components/play-pause-button/audio-or-radio-play-pause-button';
import PlayerCoverImage from '@/components/player-cover-image';
import {
  selectAudioPlayerMetadataText,
  selectPlayerMetadataText,
} from '@/store/slices/player';

import { styles } from './styles';

interface PlayerBarMobileProps {
  onClick: () => void;
  hidden?: boolean;
}

const PlayerBarMobile = forwardRef<HTMLDivElement, PlayerBarMobileProps>(
  ({ onClick, hidden }, ref) => {
    const radioMetadataText = useSelector(selectPlayerMetadataText);
    const audioMetadataText = useSelector(selectAudioPlayerMetadataText);

    const metadataText = audioMetadataText || radioMetadataText;

    return (
      <div className={styles.wrapper(hidden)} ref={ref}>
        <div className={styles.wrapper2} onClick={onClick}>
          <span className={styles.cover}>
            <PlayerCoverImage className="w-16 h-16" />
          </span>
          <span className={styles.text}>
            <span className={styles.textTop}>{metadataText?.primary}</span>
            <span className={styles.textBottom}>{metadataText?.secondary}</span>
          </span>
          <AudioOrRadioPlayPauseButton
            stopPropagation
            className={styles.button}
          />
        </div>
      </div>
    );
  },
);

export default PlayerBarMobile;
