import cls from 'classnames';
import React, { FC } from 'react';

import LoadingIcon from '@/components/icons/loading-icon';
import PauseIcon from '@/components/icons/pause-icon';
import PlayIcon from '@/components/icons/play-icon';

import { styles } from './styles';

type PlayPauseButtonProps = {
  isLoading?: boolean;
  isPlaying: boolean;
  onClick: (event: React.MouseEvent) => void;
  className?: string;
  pauseIcon?: JSX.Element;
  playIcon?: JSX.Element;
};

const PlayPauseButton: FC<PlayPauseButtonProps> = ({
  isLoading = false,
  className,
  isPlaying,
  onClick,
  pauseIcon = <PauseIcon className={styles.icon} />,
  playIcon = <PlayIcon className={styles.icon} />,
}) => {
  const Stopped = () =>
    isLoading ? <LoadingIcon className={styles.icon} /> : playIcon;

  return (
    <button
      className={cls(className, 'cursor-pointer')}
      onClickCapture={onClick}
    >
      {isPlaying ? pauseIcon : <Stopped />}
    </button>
  );
};

export default PlayPauseButton;
