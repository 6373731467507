import React, { FC } from 'react';

import { ArrowRightCircledIcon } from '@/components/icons/arrow-icon';

import { oversizeC2A as styles } from './styles';

interface OversizeC2AProps {
  text: string;
}

const OversizeC2AButton: FC<OversizeC2AProps> = ({ text: title }) => (
  <div className={styles.wrapper}>
    <span className={styles.title}>{title}</span>

    <span className={styles.rightSide}>
      <span className={styles.iconWrapper}>
        <ArrowRightCircledIcon
          className={styles.icon}
          arrowClassName={styles.iconArrow}
          circleClassName={styles.iconCircle}
        />
      </span>
    </span>
  </div>
);

export default OversizeC2AButton;
