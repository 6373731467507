import cls from 'classnames';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import useBreakpoint from '@/lib/hooks/use-breakpoint';
import usePageTheme from '@/lib/hooks/use-page-theme';
import { useStoreDispatch } from '@/store';
import {
  selectIsChannelSwitcherOpen,
  selectIsNewsletterOpen,
  toggleIsChannelSwitcherOpen,
} from '@/store/slices/global';
import { setHasLibLoaded } from '@/store/slices/player';
import { NavigationEntry, ThemeSettings } from '@/types/views/generic';

import Newsletter from '../newsletter';
import PreviewBar from '../preview-bar';
import EventLayout from './event-layout';
import MainLayout from './main-layout';
import useNavigation from './use-navigation';

interface LayoutProps {
  navigation: NavigationEntry;
  themeSettings?: ThemeSettings;
  isPreviewModeEnabled: boolean;
  deactivateAdSlots?: boolean;
  className?: string;
  hasInvertedThemeColor?: boolean;
}

const Layout: FC<LayoutProps> = ({
  navigation,
  themeSettings,
  isPreviewModeEnabled,
  className = '',
  hasInvertedThemeColor,
  deactivateAdSlots,
  children,
}) => {
  const { isNavOpen, toggleNav, setIsNavOpen } = useNavigation();
  const { currentBreakpoint } = useBreakpoint();
  const dispatch = useStoreDispatch();
  const { isEventTheme } = usePageTheme();

  const router = useRouter();
  const fullPath = router.asPath;
  const path = fullPath.split('?')[0];
  const isHome = path === '/';

  const isNewsletterOpen = useSelector(selectIsNewsletterOpen);
  const isChannelSwitcherOpen = useSelector(selectIsChannelSwitcherOpen);

  const handleChannelSwitcherToggle = () => {
    dispatch(toggleIsChannelSwitcherOpen());

    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const navbarCommonProps = {
    className: cls(
      'w-full top-0 flex items-center',
      isChannelSwitcherOpen && 'hidden md:flex',
    ),
    navigation,
    isNavOpen,
    onChannelSwitcherToggle: handleChannelSwitcherToggle,
    isChannelSwitcherOpen,
    onNavToggle: toggleNav,
  };

  return (
    <div
      id={isHome ? 'energy-home' : undefined}
      className={cls('flex flex-col', className)}
    >
      <Script
        src="/ech-new/lib/icecast-metadata-player-1.2.2.min.js"
        onLoad={() => {
          dispatch(setHasLibLoaded(true));
        }}
      />

      {isPreviewModeEnabled && <PreviewBar />}

      {isNewsletterOpen && <Newsletter />}

      {isEventTheme ? (
        <EventLayout
          {...navbarCommonProps}
          themeSettings={themeSettings}
          currentBreakpoint={currentBreakpoint}
          hasInvertedThemeColor={hasInvertedThemeColor}
          deactivateAdSlots={deactivateAdSlots}
        >
          {children}
        </EventLayout>
      ) : (
        <MainLayout
          {...navbarCommonProps}
          deactivateAdSlots={deactivateAdSlots}
        >
          {children}
        </MainLayout>
      )}
    </div>
  );
};

export default Layout;
