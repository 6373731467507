import React, { FC } from 'react';

interface VolumeIconProps {
  className?: string;
  fill?: string;
}

const VolumeIcon: FC<VolumeIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6.00555C15 5.2133 14.1226 4.73566 13.4572 5.16567L7.96702 8.71371C7.94301 8.72923 7.92851 8.75587 7.92851 8.78446C7.92851 8.83098 7.89079 8.86869 7.84427 8.86869H5C4.44772 8.86869 4 9.31641 4 9.86869V14.136C4 14.6883 4.44772 15.136 5 15.136H7.84142C7.88909 15.136 7.92773 15.1747 7.92773 15.2224C7.92773 15.2517 7.94259 15.279 7.9672 15.2949L13.4565 18.8423C14.1219 19.2723 14.9992 18.7946 14.9992 18.0024V12.0032C14.9992 12.003 14.9994 12.0028 14.9996 12.0028C14.9998 12.0028 15 12.0027 15 12.0025V6.00555Z"
      fill={fill}
    />
    <rect x="17" y="9" width="2" height="6" rx="1" fill={fill} />
  </svg>
);

export default VolumeIcon;
