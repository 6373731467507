import cls from 'classnames';

const styles = {
  wrapper: cls(
    'bg-gray-1 teair:bg-air-mediumBlue rounded-4 py-12 px-8 flex flex-col mx-8 max-w-screen-2xl 2xl:mx-auto drop-shadow-medium h-[44rem] max-h-[85vh]',
  ),
  content: cls(
    'flex-grow overflow-hidden bg-gray-1 text-white gap-x-12 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-col grid-rows-none hidden max-w-screen-2xl relative',
    'md:grid xl:grid-cols-4 2xl:mx-auto',
    'teair:bg-air-mediumBlue teair:text-white',
  ),
  headerText: 'text-h5 mb-6 h-6',
  currentRadio: 'row-span-2 col-span-1 overflow-auto flex flex-col',
  channelsWrapperOuter:
    'relative col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-3 row-span-2 overflow-auto',
  musicChannelsWrapperInner:
    'lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-x-12',
  stationChannelsWrapperInner:
    'lg:grid lg:grid-cols-2 lg:gap-x-12 xl:flex xl:gap-x-0 xl:overflow-auto mb-12',
};

export const channelList = {
  wrapper: (hasXLversion: boolean) =>
    cls(
      'flex items-center p-2 hover:bg-black hover:teair:bg-air-deepBlue rounded-2 group cursor-pointer',
      hasXLversion && 'xl:flex-col',
    ),
  image: 'w-full h-full flex-shrink-0 rounded-0.5 overflow-hidden',
  imageOverlay: (isActive: boolean) =>
    cls(
      'absolute top-0 h-full w-full bg-black opacity-70 z-40',
      isActive ? 'visible' : 'invisible group-hover:visible',
    ),
  logoWrapper: (hasRoundedLogo: boolean, hasXLversion: boolean) =>
    cls(
      ' relateive h-16 w-16 shrink-0 overflow-hidden relative z-0',
      hasRoundedLogo ? 'rounded-full' : 'rounded-1',
      hasXLversion && 'xl:h-38 xl:w-38',
    ),
  logoPlaceholder: 'w-full h-full bg-black-light',
  icon: 'w-full h-full text-white',
  textWrapper: (hasXLversion: boolean) =>
    cls(
      'flex-col mx-4 overflow-hidden',
      hasXLversion && 'xl:text-center xl:mt-4',
    ),
  textPrimary: 'text-body2 font-semibold whitespace-nowrap',
  textSecondary: (hasXLversion: boolean) =>
    cls(
      'text-overline text-gray-4 teair:text-air-lila-light line-clamp-2',
      hasXLversion && 'xl:mt-2',
    ),
  playPauseButton: (isActive: boolean, hasXLversion: boolean) =>
    cls(
      'absolute top-0 bottom-0 right-0 left-0 m-auto w-6 h-6 group-hover:visible z-50',
      isActive ? 'visible' : 'invisible',
      hasXLversion && 'xl:h-10 xl:w-10',
    ),
};

export default styles;
