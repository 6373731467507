const resolveNavigationTarget = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname === 'energy.ch') {
      const restOfUrl = url.split(parsedUrl.host)[1];

      return {
        isExternal: false,
        url: restOfUrl === '' ? '/' : restOfUrl,
      };
    }

    return {
      isExternal: true,
      url,
    };
  } catch (error) {
    return {
      isExternal: false,
      url,
    };
  }
};

export default resolveNavigationTarget;
