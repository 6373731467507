import { css } from '@emotion/css';
import cls from 'classnames';
import { useMemo } from 'react';
import { colors } from 'tailwind/theme';

import usePageTheme from '@/lib/hooks/use-page-theme';

type PlayerBgImageProps = {
  isStation?: boolean;
  logoUrl?: string;
  bgMobile?: string;
};

const bgLinearGradient = (
  hex: string,
  { r, g, b }: { r: number; g: number; b: number },
) => css`
  background-image: linear-gradient(
      90deg,
      ${hex} 0%,
      rgba(${r}, ${g}, ${b}, 0.75) 25%,
      rgba(${r}, ${g}, ${b}, 0.1) 50%,
      rgba(${r}, ${g}, ${b}, 0.75) 75%,
      ${hex} 100%
    ),
    linear-gradient(
      180deg,
      ${hex} 0%,
      rgba(${r}, ${g}, ${b}, 0.25) 7.29%,
      rgba(${r}, ${g}, ${b}, 0.6) 41.15%,
      ${hex} 73.44%
    );
`;

const baseClassName = 'h-full w-full max-h-101';

const bgImageUrlCss = (url: string) => css`
  background-image: url('${url}');
`;

const PlayerBgImage = ({
  isStation = false,
  logoUrl,
  bgMobile,
}: PlayerBgImageProps) => {
  const { pageTheme, isEventTheme } = usePageTheme();
  const isEairTheme = isEventTheme && pageTheme === 'eair';

  const bgImageClassName = useMemo(() => {
    if (isStation) {
      if (!bgMobile) return undefined;

      return bgImageUrlCss(bgMobile);
    }

    if (!logoUrl) return undefined;

    return cls('blur-3xl', bgImageUrlCss(logoUrl));
  }, [bgMobile, isStation, logoUrl]);

  return (
    <div className={cls('relative', baseClassName)}>
      <div
        className={cls(
          bgImageClassName,
          'absolute top-0 bg-center',
          baseClassName,
        )}
      />
      <div
        className={cls(
          isEairTheme
            ? bgLinearGradient(colors.air.mediumBlue, { r: 3, g: 66, b: 123 })
            : bgLinearGradient(colors.gray[1], { r: 64, g: 59, b: 69 }),
          'absolute top-0',
          baseClassName,
        )}
      />
    </div>
  );
};

export default PlayerBgImage;
