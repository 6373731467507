import React, { FC } from 'react';

interface Back10IconProps {
  className?: string;
  fill?: string;
}

const Back10Icon: FC<Back10IconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="37"
    height="43"
    viewBox="0 0 37 43"
    fill={fill}
  >
    <path d="M16.048 20.8184V31.0002H14.5068V22.3596H14.4471L12.011 23.9505V22.4789L14.5515 20.8184H16.048ZM22.347 31.1692C21.5615 31.1659 20.8903 30.9588 20.3335 30.5478C19.7767 30.1368 19.3508 29.5385 19.0558 28.753C18.7608 27.9675 18.6133 27.0213 18.6133 25.9142C18.6133 24.8106 18.7608 23.8676 19.0558 23.0854C19.3541 22.3032 19.7816 21.7066 20.3385 21.2956C20.8986 20.8847 21.5681 20.6792 22.347 20.6792C23.1259 20.6792 23.7937 20.8863 24.3505 21.3006C24.9073 21.7116 25.3332 22.3082 25.6282 23.0904C25.9265 23.8693 26.0757 24.8106 26.0757 25.9142C26.0757 27.0246 25.9282 27.9725 25.6332 28.758C25.3382 29.5402 24.9123 30.1384 24.3555 30.5527C23.7987 30.9637 23.1292 31.1692 22.347 31.1692ZM22.347 29.8418C23.0364 29.8418 23.575 29.5054 23.9627 28.8326C24.3538 28.1597 24.5494 27.187 24.5494 25.9142C24.5494 25.0691 24.4599 24.3548 24.2809 23.7715C24.1053 23.1848 23.8517 22.7407 23.5203 22.4391C23.1922 22.1342 22.8011 21.9817 22.347 21.9817C21.6609 21.9817 21.1223 22.3198 20.7312 22.9959C20.3401 23.6721 20.1429 24.6448 20.1396 25.9142C20.1396 26.7627 20.2274 27.4803 20.4031 28.0669C20.5821 28.6503 20.8356 29.0927 21.1637 29.3944C21.4919 29.6927 21.8863 29.8418 22.347 29.8418Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86551 26.0002C4.37886 33.8236 10.8717 40.0002 18.7934 40.0002C27.0496 40.0002 33.7539 33.2907 33.7539 25.0002C33.7539 16.7097 27.0496 10.0002 18.7934 10.0002C18.5391 10.0002 18.2864 10.0066 18.0353 10.0191V7.01594C18.2867 7.00548 18.5394 7.00019 18.7934 7.00019C28.7127 7.00019 36.7539 15.0591 36.7539 25.0002C36.7539 34.9413 28.7127 43.0002 18.7934 43.0002C9.20884 43.0002 1.3778 35.4762 0.860054 26.0002H3.86551Z"
    />
    <path d="M10.5323 8.78852C10.2853 8.58841 10.2853 8.21159 10.5323 8.01148L18.2183 1.78537C18.5452 1.52057 19.033 1.75321 19.033 2.17389L19.033 14.6261C19.033 15.0468 18.5452 15.2794 18.2183 15.0146L10.5323 8.78852Z" />
  </svg>
);

export default Back10Icon;
