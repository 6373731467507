import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  Document,
  Inline,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types';
import cls from 'classnames';
import * as R from 'ramda';
import { FC, ReactNode } from 'react';

import CustomLink from '@/components/custom-link';
import { mapToEntryInlineHyperlink } from '@/middleware/mappers/link';
import { TextLink } from '@/types/views/generic';

import renderEmbeddedEntry from './render-embedded-entry';
import styles from './styles';

export const renderTextLink = (node: Block | Inline, className?: string) => {
  const { data } = node;

  return (
    <CustomLink
      {...(data as TextLink)}
      className={cls(
        'font-semibold underline text-red-medium hover:text-red',
        'tels:text-els-blue-light',
        'tesn:text-esn-primary tesn:hover:text-esn-primary-light',
        'teair:text-air-coral teair:hover:text-air-coral-light',
        'game:text-game-orange game:hover:text-game-orange-light',
        className,
      )}
    >
      {data.text}
    </CustomLink>
  );
};

export const renderInlineHyperlink =
  (className = '') =>
  (node: Block | Inline) => {
    const mappedNode = mapToEntryInlineHyperlink(node);

    return mappedNode ? renderTextLink(mappedNode, className) : null;
  };

export const renderParagraph = (node: Block | Inline, children: ReactNode) => {
  const embeddedContent = node.content.filter(
    (contentItem) => contentItem.nodeType === 'embedded-entry-inline',
  );

  if (embeddedContent.length) {
    // Returning a <div> so that we don't get a <p> with invalid descendants
    return <div className={styles.PARAGRAPH}>{children}</div>;
  }

  return <p className={styles.PARAGRAPH}>{children}</p>;
};

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <strong className={styles.BOLD} key={`${text}`}>
        {text}
      </strong>
    ),
    [MARKS.ITALIC]: (text) => (
      <em className={styles.ITALIC} key={`${text}`}>
        {text}
      </em>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <span className={styles.UNDERLINE} key={`${text}`}>
        {text}
      </span>
    ),
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <h2 className={styles.HEADING_1}>{children}</h2>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <h3 className={styles.HEADING_2}>{children}</h3>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <h4 className={styles.HEADING_3}>{children}</h4>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <h5 className={styles.HEADING_4}>{children}</h5>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => renderParagraph(node, children),
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul className={styles.UL_LIST}>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <ol className={styles.OL_LIST}>{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
    [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node.data.target),
    [INLINES.HYPERLINK]: (node) => renderTextLink(node),
    [INLINES.ENTRY_HYPERLINK]: (node) => renderTextLink(node),
  },
  renderText: (text) =>
    text
      .split('\n')
      .reduce(
        (children: ReactNode[], textSegment, index): ReactNode[] => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        [],
      ),
};

interface RichTextRendererProps {
  body: Document;
  className?: string;
  options?: Options;
}

const RichTextRenderer: FC<RichTextRendererProps> = ({
  body,
  className = '',
  options,
}) => (
  <div className={className}>
    {documentToReactComponents(
      body,
      R.mergeDeepRight(defaultOptions, options || {}),
    )}
  </div>
);

export default RichTextRenderer;
