import { useCallback, useEffect, useState } from 'react';

import useAudioPlayer from './use-audio-player';
import useDeviceType from './use-device-type';

function calculateAndUpdateCurrentTime(
  xPos: number,
  setCurrentTime: (newTime: number) => void,
  element: HTMLDivElement,
  duration: number,
): void {
  if (!xPos) return;

  const rect = element.getBoundingClientRect();

  if (!rect) return;

  const { left: elLeft, width: elWidth } = rect;

  if (elWidth === 0) return;

  const xPosFromElLeft = Math.min(Math.max(xPos - elLeft, 0), elWidth);
  const progressPercent = xPosFromElLeft / elWidth;
  const newCurrentTime = duration * progressPercent;

  setCurrentTime(newCurrentTime);
}

function handleEventStart(
  clientX: number,
  setCurrentTime: (newTime: number) => void,
  setIsProgressBarSliding: (value: boolean) => void,
  element: HTMLDivElement,
  duration: number,
) {
  setIsProgressBarSliding(true);

  calculateAndUpdateCurrentTime(clientX, setCurrentTime, element, duration);
}

const useAudioScrubbing = (
  element: HTMLDivElement | null,
  duration?: number,
) => {
  const [isProgressBarSliding, setIsProgressBarSliding] = useState(false);
  const { setCurrentTime } = useAudioPlayer();
  const { isTouchDevice } = useDeviceType();

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isTouchDevice || !duration || !element) return;
    handleEventStart(
      e.clientX,
      setCurrentTime,
      setIsProgressBarSliding,
      element,
      duration,
    );
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isTouchDevice || !duration || !element) return;
    handleEventStart(
      e.touches[0].clientX,
      setCurrentTime,
      setIsProgressBarSliding,
      element,
      duration,
    );
  };

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isProgressBarSliding && !isTouchDevice && element && duration) {
        calculateAndUpdateCurrentTime(
          e.clientX,
          setCurrentTime,
          element,
          duration,
        );
      }
    },
    [isProgressBarSliding, setCurrentTime, duration, element, isTouchDevice],
  );

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isProgressBarSliding && isTouchDevice && element && duration) {
        calculateAndUpdateCurrentTime(
          e.touches[0].clientX,
          setCurrentTime,
          element,
          duration,
        );
      }
    },
    [isProgressBarSliding, setCurrentTime, duration, element, isTouchDevice],
  );

  useEffect(() => {
    const onMouseUp = () => setIsProgressBarSliding(false);

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  useEffect(() => {
    const onTouchEnd = () => setIsProgressBarSliding(false);

    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchend', onTouchEnd);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [onTouchMove]);

  return {
    onMouseDown,
    onTouchStart,
  };
};

export default useAudioScrubbing;
