import React, { FC } from 'react';

interface AudioIconProps {
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

const AudioIcon: FC<AudioIconProps> = ({
  className,
  backgroundColor = 'none',
  foregroundColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={backgroundColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.992 15.3447V3.11463C20.9912 2.94763 20.9523 2.78295 20.8783 2.63282C20.8042 2.48268 20.6968 2.35095 20.5641 2.24741C20.4333 2.14293 20.2805 2.06872 20.1168 2.03023C19.9531 1.99175 19.7828 1.98998 19.6184 2.02505L9.48516 4.24869C9.23501 4.3044 9.01161 4.44276 8.85191 4.64089C8.69222 4.83902 8.6058 5.08505 8.60695 5.33828L8.60695 14.6109C7.83833 14.2525 6.97277 14.15 6.14012 14.3186C5.30747 14.4872 4.55263 14.9178 3.98884 15.546C3.42505 16.1741 3.08271 16.9658 3.01318 17.8023C2.94364 18.6388 3.15066 19.4751 3.60317 20.1856C4.05569 20.896 4.72931 21.4425 5.52297 21.7429C6.31662 22.0433 7.18753 22.0815 8.00504 21.8518C8.82255 21.622 9.54258 21.1367 10.0571 20.4687C10.5716 19.8006 10.8529 18.9858 10.8588 18.1464C10.8704 17.9539 10.8704 17.7608 10.8588 17.5683L10.8588 6.22773L18.7402 4.50441V12.3872C17.9711 12.0287 17.105 11.9262 16.2719 12.0952C15.4389 12.2642 14.6838 12.6955 14.1202 13.3243C13.5565 13.9532 13.2147 14.7457 13.146 15.5827C13.0773 16.4198 13.2855 17.2563 13.7393 17.9665C14.1931 18.6768 14.868 19.2225 15.6626 19.5217C16.4573 19.8209 17.3288 19.8575 18.1463 19.6258C18.9637 19.3942 19.6831 18.907 20.1962 18.2373C20.7093 17.5675 20.9886 16.7515 20.992 15.9117C21.0027 15.7228 21.0027 15.5335 20.992 15.3447Z"
      fill={foregroundColor}
    />
  </svg>
);

export default AudioIcon;
