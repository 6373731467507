import cls from 'classnames';
import { FC, MouseEvent } from 'react';

import CustomLink from '@/components/custom-link';
import { HOMEPAGE_URLS } from '@/lib/constants';
import { NavItemEntry } from '@/types/views/generic';

import styles from './styles';

interface MainNavigationProps {
  navItems: NavItemEntry[];
  currentAsPath: string;
  className?: string;
  onNavItemClick?: (e: MouseEvent) => void;
  isEventTheme?: boolean;
}

const MainNavigation: FC<MainNavigationProps> = ({
  navItems,
  currentAsPath,
  className = '',
  onNavItemClick,
  isEventTheme = false,
}) => (
  <nav>
    <ul className={cls(styles.wrapper, className)}>
      {navItems.map((navItem) => {
        const navItemPath = navItem.link.as || navItem.link.href;
        const isHomepage = HOMEPAGE_URLS.includes(navItemPath);
        const isActiveForEvent = isHomepage
          ? currentAsPath === navItemPath
          : currentAsPath.includes(navItemPath);
        const isActive = isEventTheme
          ? isActiveForEvent
          : currentAsPath === navItemPath;

        return (
          <li key={navItem.link.id} className={styles.navItem}>
            {/* TODO: update bullet position after more info */}
            {isActive && <div className={styles.bullet} />}

            <CustomLink
              {...navItem.link}
              className={styles.navItemLink(isActive)}
              onClick={onNavItemClick}
            >
              {navItem.link.text}
            </CustomLink>
          </li>
        );
      })}
    </ul>
  </nav>
);

export default MainNavigation;
