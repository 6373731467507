import React, { FC } from 'react';

interface PlayIconProps {
  className?: string;
  fill?: string;
}

const PlayIcon: FC<PlayIconProps> = ({ className, fill = 'currentColor' }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <circle
      opacity="0.91"
      cx="16"
      cy="16"
      r="14.25"
      stroke={fill}
      strokeWidth="3.5"
    />
    <path
      d="M22.1275 16.9288L13.6439 21.8508C12.924 22.2681 12 21.7628 12 20.9216V11.0776C12 10.2378 12.9226 9.73117 13.6439 10.1498L22.1275 15.0718C22.2913 15.1652 22.4274 15.3004 22.5221 15.4634C22.6168 15.6265 22.6667 15.8117 22.6667 16.0003C22.6667 16.1889 22.6168 16.3741 22.5221 16.5371C22.4274 16.7002 22.2913 16.8353 22.1275 16.9288Z"
      fill={fill}
    />
  </svg>
);

export default PlayIcon;
