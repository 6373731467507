import { css } from '@emotion/css';
import Script from 'next/script';
import { FC } from 'react';

interface InstagramProps {
  src: string;
  instagramHtml?: string;
}

const styles = css`
  & > * {
    margin: 0 !important;
  }
`;

const Instagram: FC<InstagramProps> = ({ instagramHtml }) => {
  if (!instagramHtml) {
    return null;
  }

  return (
    <>
      <Script src="https://www.instagram.com/embed.js" strategy="lazyOnload" />

      <div
        dangerouslySetInnerHTML={{ __html: instagramHtml }}
        className={styles}
      />
    </>
  );
};

export default Instagram;
