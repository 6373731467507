import cls from 'classnames';
import React, { FC } from 'react';

import styles from './styles';

export type InputVariant = 'newsletter' | 'quiz';

interface FormElementProps {
  id: string;
  label: string;
  showLabel?: boolean;
  className?: string;
  helperText?: string;
  error?: string;
  blur?: boolean;
}

const FormElement: FC<FormElementProps> = ({
  children,
  id,
  label,
  showLabel,
  className,
  helperText,
  error,
  blur,
}) => {
  const hasError = !!error;

  return (
    <div className={cls(styles.inputWrapper, className && className)}>
      <label htmlFor={id} className={cls(styles.label(!!showLabel))}>
        {label}
      </label>

      {children}

      {!hasError && helperText && (
        <p className={styles.helperText}>{helperText}</p>
      )}

      {blur && error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default FormElement;
