import React, { FC, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import CustomLink from '@/components/custom-link';
import { ChevronDownIcon } from '@/components/icons/chevron-icon';
import Marquee from '@/components/marquee';
import AudioOrRadioPlayPauseButton from '@/components/play-pause-button/audio-or-radio-play-pause-button';
import PlayerCoverImage from '@/components/player-cover-image';
import getChannelUrl from '@/lib/get-channel-url';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import {
  selectAudioPlayerIsPlaying,
  selectAudioPlayerMetadataText,
  selectPlayerCurrentChannelWithGroup,
  selectPlayerIsPlaying,
  selectPlayerMetadataText,
} from '@/store/slices/player';

import VolumeButton from '../../volume-button';
import IconBar from './icon-bar';
import { styles } from './styles';

interface PlayerBarDesktopProps {
  isChannelSwitcherOpen: boolean;
  onChannelSwitcherToggle: () => void;
  small?: boolean;
  smallBarVolumeButtonRef?: React.RefObject<HTMLDivElement>;
  bigBarVolumeButtonRef?: React.RefObject<HTMLDivElement>;
}

const Text: FC = () => {
  const variables = useVariables();

  const isRadioPlaying = useSelector(selectPlayerIsPlaying);
  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);
  const channelWithGroup = useSelector(selectPlayerCurrentChannelWithGroup);
  const radioMetadataText = useSelector(selectPlayerMetadataText);

  const audioMetadataText = useSelector(selectAudioPlayerMetadataText);

  const metadataText = audioMetadataText || radioMetadataText;

  const isPlaying = isRadioPlaying || isAudioPlaying;

  return (
    <span className={styles.smallText}>
      <Marquee gradient={false} separator=" " className="whitespace-nowrap">
        {isPlaying &&
        channelWithGroup &&
        (metadataText?.primary || metadataText?.secondary) ? (
          <CustomLink
            href={getChannelUrl(
              channelWithGroup.channel,
              channelWithGroup.group,
            )}
          >
            {metadataText.primary && (
              <span className={styles.artistText}>{metadataText.primary}</span>
            )}

            {metadataText.secondary && (
              <span className={styles.titleText}>{metadataText.secondary}</span>
            )}
          </CustomLink>
        ) : (
          <span className={styles.titleText}>
            {getVariablePlainText(variables['playerbar-idle-text'])}
          </span>
        )}
      </Marquee>
    </span>
  );
};

const PlayerBarDesktop = forwardRef<HTMLButtonElement, PlayerBarDesktopProps>(
  (
    {
      isChannelSwitcherOpen,
      onChannelSwitcherToggle,
      small,
      smallBarVolumeButtonRef,
      bigBarVolumeButtonRef,
    },
    ref,
  ) => {
    const variables = useVariables();

    return small ? (
      <div className={styles.smallWrapper}>
        <div className={styles.smallWrapper2}>
          <AudioOrRadioPlayPauseButton className={styles.playPauseButton} />

          <Text />

          <VolumeButton
            className="ml-3 mr-4 w-8 h-8 bg-white text-black hover:bg-red"
            innerRef={smallBarVolumeButtonRef}
          />

          <button
            className={styles.smallChannelSwitchButton}
            onClick={onChannelSwitcherToggle}
            ref={ref}
          >
            <ChevronDownIcon
              className={styles.chevronIcon(isChannelSwitcherOpen)}
            />
          </button>
        </div>
      </div>
    ) : (
      <div className="rounded-2 game:shadow-md game:shadow-[rgba(0,0,0,0.4)]">
        {/* todo: what is this? is this still needed? */}
        <div className={styles.bigWrapper2}>
          <div className={styles.bigCoverImage}>
            <PlayerCoverImage />
          </div>

          <AudioOrRadioPlayPauseButton className={styles.playPauseButton} />

          <span className={styles.bigText}>
            <Text />
          </span>

          <IconBar volumeButtonRef={bigBarVolumeButtonRef} />

          <button
            className={styles.bigChannelSwitchButton}
            onClick={onChannelSwitcherToggle}
            ref={ref}
          >
            <span className={styles.bigChannelSwitchButtonWrapper}>
              <span>
                {getVariablePlainText(
                  variables[
                    isChannelSwitcherOpen
                      ? 'player-bar-channel-switch-close-cta-text'
                      : 'player-bar-channel-switch-open-cta-text'
                  ],
                )}
              </span>

              <ChevronDownIcon
                className={styles.chevronIcon(isChannelSwitcherOpen)}
              />
            </span>
          </button>
        </div>
      </div>
    );
  },
);

export default PlayerBarDesktop;
