import cls from 'classnames';
import { FC, RefObject, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '@/components/icon';
import MuteIcon from '@/components/icons/mute-icon';
import VolumeIcon from '@/components/icons/volume-icon';
import {
  selectPlayerIsMuted,
  selectPlayerVolume,
  toggleIsMuted,
} from '@/store/slices/player';

import VolumeSlider from './volume-slider';

interface VolumeButtonProps {
  className?: string;
  iconClassName?: string;
  muteIconClassName?: string;
  muteClassName?: string;
  volumeIconClassName?: string;
  volumeClassName?: string;
  innerRef?: RefObject<HTMLDivElement>;
  sliderPosition?: 'bottom' | 'top';
}

const VolumeButton: FC<VolumeButtonProps> = ({
  className,
  iconClassName,
  muteIconClassName,
  muteClassName,
  volumeIconClassName,
  volumeClassName,
  innerRef,
  sliderPosition = 'bottom',
}) => {
  const isMuted = useSelector(selectPlayerIsMuted);
  const volume = useSelector(selectPlayerVolume);

  const [showSlider, setShowSlider] = useState(false);

  const dispatch = useDispatch();
  const onClick = () => dispatch(toggleIsMuted());
  const onMouseEnter = () => setShowSlider(true);
  const onMouseLeave = () => setShowSlider(false);

  const shouldDisplayMutedIcon = isMuted || volume === 0;

  return (
    <div
      className="relative inline-block cursor-pointer text-white group"
      ref={innerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon
        isCircle
        isButton
        element={<MuteIcon className={cls(iconClassName, muteIconClassName)} />}
        className={cls(
          // Using css to show and hide these icons, because these way
          // we are able to check in MainNavigtion if event.target of window click event
          // is inside or outside the volume button
          // (needed to prevent closing the channel switcher after clicking volume button)
          shouldDisplayMutedIcon ? 'block' : 'hidden',
          className,
          muteClassName,
        )}
        onClick={onClick}
      />

      <Icon
        isCircle
        isButton
        element={
          <VolumeIcon className={cls(iconClassName, volumeIconClassName)} />
        }
        className={cls(
          shouldDisplayMutedIcon ? 'hidden' : 'block',
          className,
          volumeClassName,
        )}
        onClick={onClick}
      />

      <div
        className={cls(
          'absolute left-1/2 transform -translate-x-1/2 h-32 cursor-auto',
          sliderPosition === 'bottom' ? 'top-full pt-4' : 'bottom-full pb-4',
        )}
      >
        <VolumeSlider show={showSlider} sliderPosition={sliderPosition} />
      </div>
    </div>
  );
};

export default VolumeButton;
