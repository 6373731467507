import { css } from '@emotion/css';
import cls from 'classnames';

const boxShadow = css`
  box-shadow: 0 8px 40px rgba(63, 63, 63, 0.2);
`;

// FIXME: Fix after update master tailwind
const zIndex51 = css`
  z-index: 51;
`;

const styles = {
  wrapper: (isNavOpen: boolean) =>
    cls(
      `z-30 flex flex-col max-h-screen items-stretch teair:bg-white tesn:bg-black teds:bg-eds-black tels:bg-black py-2 xl:py-0 ${boxShadow}`,
      isNavOpen
        ? 'fixed top-0 left-0 w-screen h-screen bg-black safari-100vh-hack'
        : `sticky ${boxShadow}`,
    ),
  contentContainer:
    '2xl:content-box flex flex-shrink-0 justify-between items-center xl:py-4 px-4 xl:px-6 2xl:px-0 relative',
  energyLogoWrapper: (isNavVisible: boolean, isLiveSessionEvent = false) =>
    cls(
      `flex-grow-0 xl:flex-shrink-0 xl:inline pr-4 2xl:fixed 2xl:-ml-56 xl:flex-0 transform transition-transform duration-500`,
      isNavVisible
        ? '2xl:teair:translate-y-36 2xl:tesn:translate-y-20 2xl:teds:translate-y-10 tels:translate-y-16 xl:tels:translate-y-22'
        : '2xl:teair:translate-y-56 2xl:tesn:translate-y-36 2xl:teds:translate-y-28 tels:translate-y-30 xl:tels:translate-y-38',
      isLiveSessionEvent && `fixed ${zIndex51}`,
    ),
  iconsWrapper:
    'flex flex-grow flex-shrink-0 xl:flex-1 xl:flex-shrink-0 min-w-max justify-end items-center',
  navigation:
    'overflow-y-auto bg-white fixed h-screen top-16 z-50 w-full tesn:bg-black teds:bg-eds-black tels:bg-black',
};

export default styles;
