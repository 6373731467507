import cls from 'classnames';

export const styles = {
  playPauseButton: 'flex-shrink-0 w-12 h-12 p-2',
  smallWrapper: 'flex justify-center',
  smallWrapper2: 'flex items-center bg-black-light rounded-full min-w-0',
  smallText:
    'flex items-center whitespace-nowrap min-w-0 overflow-hidden text-ellipsis',
  smallChannelSwitchButton:
    'inline-block self-stretch bg-pink pl-1 pr-3 rounded-r-full',
  chevronIcon: (isChannelSwitcherOpen: boolean) =>
    cls(
      'inline-block text-black w-8 h-8 p-1 transition-transform',
      isChannelSwitcherOpen && 'transform rotate-180',
    ),
  bigWrapper2: 'flex items-center bg-black-light rounded-2',
  bigCoverImage: 'w-12 h-12 rounded-l-2 overflow-hidden',
  bigText:
    'flex items-center mr-2 flex-1 whitespace-nowrap min-w-0 overflow-hidden text-ellipsis',
  bigChannelSwitchButton: 'inline-block self-stretch bg-pink p-2 rounded-r-2',
  bigChannelSwitchButtonWrapper:
    'inline-flex items-center rounded-full px-4 font-semibold bg-white text-black text-body2',
  artistText: 'inline text-body1 font-semibold mr-2',
  titleText: 'inline text-overline text-gray-3',
};
