import { css } from '@emotion/css';
import cls from 'classnames';

export const styles = {
  wrapper: (hidded?: boolean) => cls('w-screen p-4', hidded && 'hidden'),
  wrapper2: cls(
    'flex items-center bg-black-light rounded-2 drop-shadow-[0px_0px_16px_rgba(0,0,0,0.5)] overflow-hidden z-0',
    css`
      transform: translateZ(0);
    `,
  ),
  cover: 'w-16 h-16 min-w-16 min-h-16',
  text: 'inline-flex flex-col px-4 flex-grow min-w-0 whitespace-nowrap h-16 justify-center pointer-events-none',
  textTop: 'inline-block max-w-full overflow-hidden text-ellipsis text-body2',
  textBottom:
    'inline-block max-w-full overflow-hidden text-ellipsis text-overline text-gray-2',
  button: 'w-16 h-16 min-w-16 min-h-16 py-3 px-3 mr-1',
};
