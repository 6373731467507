import Script from 'next/script';

interface FacebookProps {
  src: string;
}

const Facebook = ({ src }: FacebookProps) => (
  <>
    <Script
      src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
      strategy="lazyOnload"
    />

    <div
      className="fb-video"
      data-href={src}
      data-width="auto"
      data-show-text="false"
    />
  </>
);

export default Facebook;
