import { FC } from 'react';

import { Channel as ChannelT, ChannelGroupType } from '@/types/views/generic';

import Channel from './channel';

interface ChannelListProps {
  channels: ChannelT[];
  channelGroupType: ChannelGroupType;
  onClick: () => void;
}

const ChannelList: FC<ChannelListProps> = ({
  channels,
  onClick,
  channelGroupType,
}) => (
  <>
    {channels.map((channel) => (
      <Channel
        key={channel.id}
        channel={channel}
        channelGroupType={channelGroupType}
        onClick={onClick}
      />
    ))}
  </>
);

export default ChannelList;
