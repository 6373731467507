import { Channel, ChannelGroupType } from '@/types/views/generic';

const getChannelUrl = (
  channel: Channel,
  group: ChannelGroupType,
  isPlaylistUrl = false,
) => {
  const { id, slug } = channel;
  const playlistPath = isPlaylistUrl ? '/playlist' : '';

  const groupUrls = {
    // TODO: Refactor this because we have different slug/bmid for Energy stations
    [ChannelGroupType.Station]: `/stations/${slug || id}${playlistPath}`,
    [ChannelGroupType.Music]: `/channels/${slug || id}`,
    // Added to avoid TS error
    [ChannelGroupType.External]: '',
  };

  return groupUrls[group] || '';
};

export default getChannelUrl;
