import { INLINES } from '@contentful/rich-text-types';

import newsletterSuccess from '@/assets/form-images/form-success.svg';
import Image from '@/components/image';
import RichTextRenderer, {
  renderInlineHyperlink,
} from '@/components/rich-text-renderer';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { ImageAsset } from '@/types/views/generic';

import styles from '../first-step/styles';

type SuccessBlockTypes = {
  newsletterSuccessImage?: ImageAsset;
};
const SuccessBlock = ({ newsletterSuccessImage }: SuccessBlockTypes) => {
  const variables = useVariables();
  const successImage = newsletterSuccessImage || newsletterSuccess.src;

  return (
    <div className={styles.wrapper}>
      <figure className={styles.imageContainer}>
        <Image
          src={successImage}
          alt="Success subscription to newsletter"
          objectFit="scale-down"
        />
      </figure>

      <div className={styles.leftSide}>
        <h2 className={styles.heading}>
          {getVariablePlainText(
            variables['newsletter-email-success-block-title'],
          )}
        </h2>

        <div className={styles.text}>
          <RichTextRenderer
            className={styles.text}
            body={variables['newsletter-email-success-block-text']}
            options={{
              renderNode: {
                [INLINES.HYPERLINK]: renderInlineHyperlink(styles.text),
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessBlock;
