import { useState } from 'react';

export const PREVIEW_DISABLE_URL = '/api/preview/disable';

const usePreviewBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  const close = () => setIsVisible(false);

  const disablePreview = async () => {
    await fetch(PREVIEW_DISABLE_URL);
    window.location.reload();
  };

  return {
    isVisible,
    close,
    disablePreview,
  };
};

export default usePreviewBar;
