import React, { FC, useMemo, useRef } from 'react';

import CountdownProgressBar from '@/components/countdowns/countdown-progress-bar';
import useAudioScrubbing from '@/lib/hooks/use-audio-scrubbing';

export const getTimeUnitsRemainders = (timeInSeconds: number) => {
  const seconds = Math.floor(timeInSeconds) % 60;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const hours = Math.floor(timeInSeconds / 3600) % 24;

  const secondsString = seconds >= 10 ? seconds.toString() : `0${seconds}`;
  const minutesString = minutes >= 10 ? minutes.toString() : `0${minutes}`;

  return {
    seconds: secondsString,
    minutes: minutesString,
    hours,
  };
};

interface CountdownsProps {
  className?: string;
  frequency?: string;
  duration?: number;
  currentTime?: number;
  isLive?: boolean;
}

const fullDuration = 100;

const Countdowns: FC<CountdownsProps> = ({
  className,
  frequency,
  duration,
  currentTime,
  isLive = false,
}) => {
  const timeLeft =
    !isLive && duration && currentTime ? duration - currentTime : 0;

  const getCurrentTimestamp = () => {
    if (!currentTime || !duration) return undefined;

    const { hours: durationHours } = getTimeUnitsRemainders(duration);
    const { hours, minutes, seconds } = getTimeUnitsRemainders(currentTime);

    return `${durationHours ? `${hours}:` : ''}${minutes}:${seconds}`;
  };

  const endTimestamp = useMemo(() => {
    if (!duration) return undefined;

    const { hours, minutes, seconds } = getTimeUnitsRemainders(duration);

    return `${hours ? `${hours}:` : ''}${minutes}:${seconds}`;
  }, [duration]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { onMouseDown, onTouchStart } = useAudioScrubbing(
    wrapperRef.current,
    duration,
  );

  return (
    <div className={className}>
      <span className="text-overline text-gray-3 font-medium">{frequency}</span>
      <div
        className="mt-2 mb-1"
        ref={wrapperRef}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      >
        <CountdownProgressBar
          backgroundColor="bg-black-extra"
          duration={!isLive && duration ? duration : fullDuration}
          timeLeft={timeLeft}
        />
      </div>

      <div className="flex md:justify-between justify-end">
        <div className="hidden md:flex">
          <span className="text-body2 text-white">
            {isLive ? '--:--' : getCurrentTimestamp()}
          </span>
        </div>
        <span className="text-body2 text-white">
          {isLive ? 'Live' : endTimestamp}
        </span>
      </div>
    </div>
  );
};

export default Countdowns;
