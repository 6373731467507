import cls from 'classnames';
import { FC } from 'react';

import styles from '@/components/footer/styles';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface FooterCookieButtonProps {
  hasCustomFooter?: boolean;
}

const FooterCookieButton: FC<FooterCookieButtonProps> = ({
  hasCustomFooter = false,
}) => {
  const variables = useVariables();

  const openCookieBanner = () => window?.OneTrust.ToggleInfoDisplay();

  return (
    <li
      className={cls(styles.listElement(hasCustomFooter), 'cursor-pointer')}
      onClick={openCookieBanner}
    >
      {getVariablePlainText(variables['cookie-settings']) || 'Cookie Settings'}
    </li>
  );
};

export default FooterCookieButton;
