import { css } from '@emotion/css';

import TailwindTheme from '@/../tailwind/theme';

const { screens } = TailwindTheme;

const imageStyles = css`
  height: 191px;

  @media (min-width: ${screens.md}) {
    height: 328px;
  }
`;

const imageContainerStyles = (isEsnTheme: boolean) => css`
  max-width: ${isEsnTheme ? 404 : 493}px;
`;

const styles = {
  content: 'text-center',
  imageContainer: (isEsnTheme: boolean) =>
    `${imageContainerStyles(isEsnTheme)} mx-auto w-full md:order-2`,
  image: `${imageStyles} w-full`,
  heading: 'text-h3 mt-8 md:mt-10 mb-4 teair:text-white',
  description: 'text-body1 mb-6 teair:text-white',
  btnContainer: 'text-center mt-6',
  cancelBtn: 'text-body2 text-gray-2 tesn:text-esn-secondary-light underline',
};

export default styles;
