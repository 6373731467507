const throttle = <I, O>(
  callback: (...callbackArgs: I[]) => O,
  limit: number,
) => {
  let locked = false;

  return (...args: I[]) => {
    if (!locked) {
      locked = true;
      const ret = callback(...args);
      setTimeout(() => {
        locked = false;
      }, limit);

      return ret;
    }

    return null;
  };
};

export default throttle;
