import cls from 'classnames';
import { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ImageSectionEntry } from '@/types/views/sections';

const EmbeddedImage: FC<ImageSectionEntry> = ({
  image,
  link,
  imageMobile,
  description,
}) => {
  const ImageElement = () =>
    // TODO: investigate why this is necessary so parent p-tags in articles get rendered with correct props
    // Maybe InView is the issue?
    typeof window !== 'undefined' ? (
      <>
        <Image
          className={cls(imageMobile && 'hidden md:block')}
          src={image.src}
          useIntrinsicSize
          width={image.width}
          height={image.height}
        />

        {imageMobile && (
          <Image
            className="md:hidden"
            src={imageMobile.src}
            useIntrinsicSize
            width={imageMobile.width}
            height={imageMobile.height}
          />
        )}
      </>
    ) : null;

  return (
    <div className="text-box leading-[0]">
      {link ? (
        <CustomLink className="inline-block" {...link}>
          <ImageElement />
        </CustomLink>
      ) : (
        <ImageElement />
      )}
      {description && (
        <div className="mt-2 md:mt-4">
          <span className="italic text-overline font-normal">
            {description}
          </span>
        </div>
      )}
    </div>
  );
};

export default EmbeddedImage;
