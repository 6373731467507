import cls from 'classnames';

import { InputVariant } from '.';

const smallText = 'text-small mt-1';

const styles = {
  inputWrapper: 'w-full',
  label: (showLabel: boolean) =>
    cls('text-overline mb-2', !showLabel && 'hidden'),
  input: (variant: InputVariant, hasError = false, blur = false) =>
    cls(
      'w-full rounded-2 py-3 px-4 leading-snug focus:shadow-none focus:ring-0',
      !hasError && 'border-transparent focus:border-gray-2',
      variant === 'newsletter' &&
        'text-red-light bg-black bg-opacity-50 focus:border-gray-2 tesn:bg-air-lila-light tesn:bg-opacity-20 teair:bg-air-lila-light teair:bg-opacity-20',
      variant === 'quiz' && 'teair:bg-white teair:border-black border-2',
      hasError && blur && 'border-red-medium focus:border-red-medium',
    ),
  helperText: cls('text-gray-2', smallText),
  errorText: cls('text-red-medium h-5', smallText),
};

export default styles;
