import { useState } from 'react';

const useModal = (initialState?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState || false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  return {
    isModalOpen: isOpen,
    setIsModalOpen: setIsOpen,
    toggleModal: toggle,
  };
};

export default useModal;
