import cls from 'classnames';
import { FC, MouseEvent } from 'react';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import { NavItemEntry } from '@/types/views/generic';

import styles from './styles';

interface SmallLabelNavigationProps {
  navItems: NavItemEntry[];
  className?: string;
  onNavItemClick?: (e: MouseEvent) => void;
}

const SmallLabelNavigation: FC<SmallLabelNavigationProps> = ({
  navItems,
  className = '',
  onNavItemClick,
}) => (
  <ul className={cls(styles.wrapper, className)}>
    {navItems.map((navItem) => {
      const { link } = navItem;

      return (
        <li key={link.id} className="mb-2 mr-2 last:mr-0">
          <CustomLink {...link} onClick={onNavItemClick}>
            <NormalButton
              text={link.text}
              size="S"
              color="gray"
              variant="ghost"
              className={styles.button}
            />
          </CustomLink>
        </li>
      );
    })}
  </ul>
);

export default SmallLabelNavigation;
