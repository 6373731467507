import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'tailwind/theme';

import PlayIconSolid from '@/components/icons/play-icon-solid';
import PlaylistIcon from '@/components/icons/playlist-icon';
import Image from '@/components/image';
import RadioPlayPauseButton from '@/components/play-pause-button/radio-play-pause-button';
import getChannelUrl from '@/lib/get-channel-url';
import usePageTheme from '@/lib/hooks/use-page-theme';
import usePlayer from '@/lib/hooks/use-player';
import { mapUrlToLink } from '@/middleware/mappers/link';
import {
  selectPlayerCurrentChannelId,
  selectPlayerCurrentChannelWithGroup,
  selectPlayerIsPlaying,
  setCurrentChannelId,
} from '@/store/slices/player';
import { Channel as ChannelT, ChannelGroupType } from '@/types/views/generic';

import { channel as styles } from './styles';

interface ChannelProps {
  channel: ChannelT;
  isEventTheme: boolean;
  channelGroup: ChannelGroupType;
  onPlaylistIconClick: () => void;
}

const Channel: FC<ChannelProps> = ({
  channel,
  channelGroup,
  isEventTheme,
  onPlaylistIconClick,
}) => {
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const currentChannelId = useSelector(selectPlayerCurrentChannelId);
  const currentChannelWithGroup = useSelector(
    selectPlayerCurrentChannelWithGroup,
  );

  const dispatch = useDispatch();

  const isActive = currentChannelId === channel.id;

  const player = usePlayer();

  const { pageTheme } = usePageTheme();
  const isEairTheme = pageTheme === 'eair';

  const playlistUrl = currentChannelWithGroup
    ? getChannelUrl(channel, channelGroup, true)
    : null;

  const playlistLink = playlistUrl ? mapUrlToLink(playlistUrl) : null;

  const onClick = () => {
    if (isPlaying) {
      dispatch(setCurrentChannelId(channel.id));
    } else {
      player?.play();
      dispatch(setCurrentChannelId(channel.id));
    }
  };

  return (
    <div className={styles.wrapper(isActive)} onClick={onClick}>
      <span className={styles.cover(channelGroup === ChannelGroupType.Station)}>
        {channel.logoUrl ? <Image src={channel.logoUrl} basicLoader /> : null}
      </span>

      <span className={styles.name}>{channel.name}</span>

      {!isEventTheme && (
        <PlaylistIcon
          className={styles.playlistIcon}
          link={playlistLink}
          onClick={onPlaylistIconClick}
        />
      )}

      <RadioPlayPauseButton
        isActive={isActive}
        playIcon={
          <PlayIconSolid
            {...(isEairTheme && {
              foregroundColor: colors.air.deepBlue,
              backgroundColor: colors.white,
            })}
          />
        }
        className={styles.playPauseButton}
        channelId={channel.id}
        stopPropagation
      />
    </div>
  );
};

export default Channel;
