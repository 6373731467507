import React, { FC } from 'react';

interface Back30IconProps {
  className?: string;
  fill?: string;
}

const Back30Icon: FC<Back30IconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="27"
    height="34"
    viewBox="0 0 27 34"
    fill={fill}
  >
    <path d="M6.397 24.12c-.585 0-1.108-.102-1.568-.303a2.662 2.662 0 0 1-1.086-.84 2.229 2.229 0 0 1-.427-1.253h1.338c.018.259.104.483.26.674.16.187.367.332.623.434.255.103.54.154.852.154.344 0 .648-.06.912-.18a1.49 1.49 0 0 0 .626-.498c.15-.216.226-.464.226-.745 0-.293-.075-.55-.226-.772a1.471 1.471 0 0 0-.652-.528c-.284-.128-.628-.192-1.031-.192h-.737v-1.074h.737c.324 0 .608-.058.852-.175.247-.116.44-.278.58-.485.139-.21.209-.456.209-.738 0-.27-.061-.504-.184-.703a1.23 1.23 0 0 0-.511-.473 1.662 1.662 0 0 0-.776-.17c-.284 0-.55.052-.796.157-.245.103-.444.25-.597.444a1.14 1.14 0 0 0-.247.686H3.5c.014-.472.153-.887.417-1.244a2.7 2.7 0 0 1 1.057-.84 3.44 3.44 0 0 1 1.457-.303c.56 0 1.043.11 1.45.329.408.215.724.504.945.865.225.36.336.755.333 1.184.003.489-.134.904-.41 1.245-.272.34-.636.57-1.09.686v.068c.58.088 1.028.318 1.346.69.321.372.48.834.478 1.385.002.48-.131.91-.401 1.291-.267.381-.632.68-1.095.9-.463.215-.993.323-1.59.323Zm7.735.025c-.673-.003-1.249-.18-1.726-.533-.477-.352-.842-.865-1.095-1.538s-.38-1.485-.38-2.433c0-.946.127-1.755.38-2.425.255-.67.622-1.182 1.1-1.534.48-.353 1.053-.529 1.72-.529.668 0 1.24.178 1.718.533.477.352.842.864 1.095 1.534.256.668.384 1.475.384 2.42 0 .952-.127 1.765-.38 2.438-.252.67-.617 1.183-1.095 1.538-.477.353-1.05.529-1.721.529Zm0-1.138c.59 0 1.052-.288 1.385-.865.335-.577.503-1.41.503-2.501 0-.725-.077-1.337-.23-1.837-.151-.503-.368-.883-.653-1.142a1.427 1.427 0 0 0-1.005-.392c-.588 0-1.05.29-1.385.87-.335.579-.505 1.413-.507 2.5 0 .728.075 1.343.226 1.846.153.5.37.88.652 1.138.28.255.619.383 1.014.383Z" />
    <path d="M4.791 28.334a11.416 11.416 0 0 0 7.904 3.166c6.329 0 11.47-5.144 11.47-11.5S19.023 8.5 12.694 8.5V6c7.715 0 13.97 6.268 13.97 14s-6.255 14-13.97 14a13.92 13.92 0 0 1-10.308-4.55l2.404-1.116Z" />
    <path d="M6.43 7.389a.5.5 0 0 1 0-.778l6.188-5.013a.5.5 0 0 1 .815.388v10.028a.5.5 0 0 1-.815.388L6.43 7.39Z" />
    <path d="M.442 6.611a.5.5 0 0 0 0 .777l6.19 5.014a.5.5 0 0 0 .814-.388v-.568L2.438 7.388a.5.5 0 0 1 0-.777l5.008-4.057v-.568a.5.5 0 0 0-.815-.388L.442 6.61Z" />
  </svg>
);

export default Back30Icon;
