import { INLINES } from '@contentful/rich-text-types';
import React, { FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import Input from '@/components/form-elements/input';
import RichTextRenderer, {
  renderInlineHyperlink,
} from '@/components/rich-text-renderer';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';

import styles from './styles';

type FormInputData = {
  email: string;
  firstName: string;
  lastName: string;
};

type SecondStepProps = {
  register: UseFormRegister<FormInputData>;
  errors: FieldErrors;
  buttonDisabled: boolean;
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  inputValues: {
    firstName: string;
    lastName: string;
  };
};

const SecondStep: FC<SecondStepProps> = ({
  register,
  errors,
  buttonDisabled,
  onPrevButtonClick,
  onNextButtonClick,
  inputValues,
}) => {
  const variables = useVariables();
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);
  const title = getVariablePlainText(variables['newsletter-enter-name-text']);
  const text = variables['newsletter-datenschutz-text'];
  const firstName = getVariablePlainText(
    variables['newsletter-input-name-text'],
  );
  const lastName = getVariablePlainText(
    variables['newsletter-input-surname-text'],
  );
  const ctaBtnText = getVariablePlainText(variables['newsletter-text']);
  const cancelBtnText = getVariablePlainText(
    variables['newsletter-cancel-text'],
  );
  const firstNameErrorText = getVariablePlainText(
    variables['first-name-error-text'],
  );
  const lastNameErrorText = getVariablePlainText(
    variables['last-name-error-text'],
  );

  return (
    <div>
      <h2 className={styles.heading}>{title}</h2>

      <RichTextRenderer
        body={text}
        className={styles.text}
        options={{
          renderNode: {
            [INLINES.HYPERLINK]: renderInlineHyperlink(styles.text),
          },
        }}
      />

      <div className={styles.formGroup}>
        <Input
          {...register('firstName', {
            required: firstNameErrorText || 'First name is required',
          })}
          id="firstName"
          label={firstName || 'Vorname'}
          type="text"
          placeholder={firstName || 'Vorname'}
          containerClassName={styles.inputWrapper}
          className={styles.input(!!inputValues.firstName)}
          error={errors?.firstName?.message}
        />

        <Input
          {...register('lastName', {
            required: lastNameErrorText || 'Last name is required',
          })}
          id="lastName"
          label={lastName || 'Nachname'}
          type="text"
          placeholder={lastName || 'Nachname'}
          containerClassName={styles.inputWrapper}
          className={styles.input(!!inputValues.lastName)}
          error={errors?.lastName?.message}
        />

        <NormalButton
          {...buttonProps}
          text={ctaBtnText}
          size="L"
          className={styles.button}
          onClick={onNextButtonClick}
          disabled={buttonDisabled}
        />
      </div>

      <div className={styles.btnContainer}>
        <button
          type="button"
          onClick={onPrevButtonClick}
          className={styles.cancelBtn}
        >
          {cancelBtnText}
        </button>
      </div>
    </div>
  );
};

export default SecondStep;
