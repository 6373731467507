import { useLayoutEffect } from 'react';

let isLocked = false;

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    const wasLockedInitially = isLocked;

    if (!wasLockedInitially) {
      document.body.style.overflow = 'hidden';
      isLocked = true;
    }

    return () => {
      if (!wasLockedInitially) {
        document.body.style.overflow = originalStyle;
        isLocked = false;
      }
    };
  }, []);
};

export default useLockBodyScroll;
