import React, { FC } from 'react';

import { ComponentEntry } from '@/types/views/generic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComponentsMap = Record<string, FC<any> | undefined>;

const renderComponent =
  (components: ComponentsMap) =>
  (entry: ComponentEntry): JSX.Element | null => {
    const Component = components[entry.componentType];

    if (!Component) {
      return null;
    }

    return <Component key={entry.id} {...entry} />;
  };

export default renderComponent;
