import React, { FC } from 'react';

interface PauseIconProps {
  className?: string;
  fill?: string;
}

const PauseIcon: FC<PauseIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    className={className}
  >
    <circle
      opacity="0.91"
      cx="16"
      cy="16"
      r="14.25"
      stroke={fill}
      strokeWidth="3.5"
    />
    <rect
      x="18.6504"
      y="9.60156"
      width="3.74956"
      height="12.8"
      rx="1"
      fill={fill}
    />
    <rect
      x="9.59961"
      y="9.60156"
      width="3.74956"
      height="12.8"
      rx="1"
      fill={fill}
    />
  </svg>
);

export default PauseIcon;
