import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ThemeSettings } from '@/types/views/generic';

interface NavbarEnergyLogoProps {
  wrapperClassName: string;
  dividerClassName: string;
  themeSettings: ThemeSettings;
}

const NavbarEnergyLogo: FC<NavbarEnergyLogoProps> = ({
  wrapperClassName,
  dividerClassName,
  themeSettings,
}) => {
  const { energyLogo } = themeSettings;

  if (!energyLogo) return null;

  return (
    <div className={`relative ${wrapperClassName}`}>
      <div className={`absolute -top-1 h-10 w-px ${dividerClassName}`} />

      <CustomLink href="/" className="ml-4">
        <Image
          src={energyLogo.src}
          useIntrinsicSize
          width={energyLogo.width}
          height={energyLogo.height}
          alt={`${themeSettings.title} - Energy logo`}
        />
      </CustomLink>
    </div>
  );
};

export default NavbarEnergyLogo;
