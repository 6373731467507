import { INLINES } from '@contentful/rich-text-types';
import { FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';

import newsletterImage from '@/assets/form-images/form-image.svg';
import NormalButton from '@/components/button/normal';
import Input from '@/components/form-elements/input';
import Image from '@/components/image';
import RichTextRenderer, {
  renderInlineHyperlink,
} from '@/components/rich-text-renderer';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { ImageAsset } from '@/types/views/generic';

import styles from './styles';

const EMAIL_REGEX_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

type FormInputData = {
  email: string;
  firstName: string;
  lastName: string;
};

type FirstStepProps = {
  register: UseFormRegister<FormInputData>;
  errors: FieldErrors;
  buttonDisabled: boolean;
  onNextButtonClick: () => void;
  newsletterMobileImage?: ImageAsset;
  newsletterDesktopImage?: ImageAsset;
};

const FirstStep: FC<FirstStepProps> = ({
  register,
  errors,
  buttonDisabled,
  onNextButtonClick,
  newsletterMobileImage,
  newsletterDesktopImage,
}) => {
  const variables = useVariables();
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);
  const isEsnTheme = pageTheme === 'esn';
  const isElsTheme = pageTheme === 'els';
  const signupText =
    variables[
      isEsnTheme ? 'newsletter-signup-text-esn' : 'newsletter-signup-text'
    ];
  const emailPlaceholderText = getVariablePlainText(
    variables['newsletter-signup-email-text'],
  );
  const emailButtonText = getVariablePlainText(
    variables['newsletter-signup-cta-text'],
  );
  const emailErrorText = getVariablePlainText(variables['email-error-text']);
  const invalidEmailText = getVariablePlainText(
    variables['invalid-email-text'],
  );

  const { isMobile } = useBreakpoint();
  const staticNewsletterImage = isMobile
    ? newsletterMobileImage?.src
    : newsletterDesktopImage?.src;

  return (
    <div className={styles.wrapper}>
      {!isElsTheme && (
        <figure className={styles.imageContainer}>
          <Image
            src={staticNewsletterImage || newsletterImage.src}
            alt="Save to newsletter"
            objectFit="contain"
          />
        </figure>
      )}

      <div className={styles.leftSide}>
        <RichTextRenderer
          className={styles.heading}
          body={signupText}
          options={{
            renderNode: {
              [INLINES.HYPERLINK]: renderInlineHyperlink('text-h4'),
            },
          }}
        />

        <div className={styles.rowGroup}>
          <Input
            {...register('email', {
              required: emailErrorText || 'Email is required',
              pattern: {
                value: EMAIL_REGEX_PATTERN,
                message: invalidEmailText || 'Please enter a valid email.',
              },
            })}
            id="email"
            label={emailPlaceholderText || 'Email Address'}
            type="email"
            placeholder={emailPlaceholderText || 'Email Address'}
            containerClassName="md:mr-4"
            className={styles.input}
            error={errors?.email?.message}
          />

          <NormalButton
            {...buttonProps}
            text={emailButtonText}
            size="L"
            className={styles.button}
            onClick={onNextButtonClick}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
