import cls from 'classnames';
import React, { FC } from 'react';

interface PauseIconThinProps {
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  strokeWidth?: number;
}

const PauseIconThin: FC<PauseIconThinProps> = ({
  className,
  backgroundColor = 'none',
  foregroundColor = 'currentColor',
  strokeWidth = '3',
}) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    className={cls(className, 'group')}
  >
    <circle
      cx="28"
      cy="28"
      r="26.5"
      fill={backgroundColor}
      stroke={foregroundColor}
      strokeWidth={strokeWidth}
    />
    <rect
      x="31.8623"
      y="18.3457"
      width="5.7931"
      height="19.3103"
      rx="1.5"
      className="group-hover:fill-red"
      fill={foregroundColor}
    />
    <rect
      x="18.3447"
      y="18.3457"
      width="5.7931"
      height="19.3103"
      rx="1.5"
      className="group-hover:fill-red"
      fill={foregroundColor}
    />
  </svg>
);

export default PauseIconThin;
