import cls from 'classnames';

const styles = {
  iconsContainer: 'flex items-center',
  iconItem: (isActive: boolean) =>
    cls(
      isActive ? 'text-red' : 'text-white',
      'inline-flex flex-shrink-0 leading-[0] content-after hover:text-red-medium',
    ),
  quickNavIcon: 'inline-block py-4 px-2',
  navigationIconItem: 'pl-2 md:pl-4 inline-flex items-center',
  iconButton: 'focus:outline-none bg-white py-1 pr-1 pl-3 text-black',
  navigationIcon: 'w-16 xl:w-20 xl:h-10',
  divider: 'self-center border-r border-gray-1 h-10 w-0',
};

export default styles;
