import cls from 'classnames';

const styles = {
  wrapper: 'flex-grow bg-gray-1 overflow-auto',
  headerText: 'text-h4 mb-4',
  closeIcon:
    'fixed top-5 right-5 w-8 h-8 rounded-full bg-white text-black inline-flex items-center justify-center cursor-pointer',
  divider: 'border-t border-gray-2 teair:border-air-lila-light mx-5',
};

export const channelList = {
  text: 'inline-block content-box mb-4 text-h4 teair:text-white',
  wrapper: 'py-6 teair:bg-air-mediumBlue',
};

export const channel = {
  wrapper: (isActive: boolean) =>
    cls(
      'content-box flex items-center hover:bg-gray-1 hover:teair:bg-air-deepBlue py-3',
      isActive && 'bg-gray-1 bg-opacity-30',
    ),
  cover: (isLogoRounded: boolean) =>
    cls(
      'w-10 h-10 overflow-hidden z-0',
      isLogoRounded ? 'rounded-full' : 'rounded-0.5',
    ),
  name: 'inline-block text-body1 flex-grow ml-4 teair:text-white',
  playlistIcon: 'mx-6 inline-block teair:text-white',
  playPauseButton: 'w-8 h-8',
};

export default styles;
