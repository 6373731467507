import { FC } from 'react';

interface ProfileIconProps {
  className: string;
  fill?: string;
}

const ProfileIcon: FC<ProfileIconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <circle cx="12" cy="9" r="3" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17.451 17.8555C19.0192 16.395 20 14.3121 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.3039 4.9739 16.3804 6.53249 17.8401C7.49318 15.5585 9.58506 14 12.0006 14C14.4216 14 16.4814 15.5655 17.451 17.8555Z"
      fill={fill}
    />
  </svg>
);

export default ProfileIcon;
