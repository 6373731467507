import React, { FC } from 'react';

interface Forward10IconProps {
  className?: string;
  fill?: string;
}

const Forward10Icon: FC<Forward10IconProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    className={className}
    width="37"
    height="43"
    viewBox="0 0 37 43"
    fill={fill}
  >
    <path d="M15.7018 20.8184V31.0002H14.1606V22.3596H14.1009L11.6649 23.9505V22.4789L14.2053 20.8184H15.7018ZM22.0008 31.1692C21.2153 31.1659 20.5441 30.9588 19.9873 30.5478C19.4305 30.1368 19.0046 29.5385 18.7096 28.753C18.4146 27.9675 18.2671 27.0213 18.2671 25.9142C18.2671 24.8106 18.4146 23.8676 18.7096 23.0854C19.0079 22.3032 19.4354 21.7066 19.9923 21.2956C20.5524 20.8847 21.2219 20.6792 22.0008 20.6792C22.7797 20.6792 23.4475 20.8863 24.0043 21.3006C24.5612 21.7116 24.9871 22.3082 25.282 23.0904C25.5803 23.8693 25.7295 24.8106 25.7295 25.9142C25.7295 27.0246 25.582 27.9725 25.287 28.758C24.992 29.5402 24.5661 30.1384 24.0093 30.5527C23.4525 30.9637 22.783 31.1692 22.0008 31.1692ZM22.0008 29.8418C22.6902 29.8418 23.2288 29.5054 23.6166 28.8326C24.0077 28.1597 24.2032 27.187 24.2032 25.9142C24.2032 25.0691 24.1137 24.3548 23.9347 23.7715C23.7591 23.1848 23.5055 22.7407 23.1741 22.4391C22.846 22.1342 22.4549 21.9817 22.0008 21.9817C21.3147 21.9817 20.7761 22.3198 20.385 22.9959C19.9939 23.6721 19.7967 24.6448 19.7934 25.9142C19.7934 26.7627 19.8812 27.4803 20.0569 28.0669C20.2359 28.6503 20.4894 29.0927 20.8175 29.3944C21.1457 29.6927 21.5401 29.8418 22.0008 29.8418Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4021 26.0002C32.8887 33.8236 26.3958 40.0002 18.4742 40.0002C10.2179 40.0002 3.51367 33.2907 3.51367 25.0002C3.51367 16.7097 10.2179 10.0002 18.4742 10.0002C18.7284 10.0002 18.9812 10.0066 19.2323 10.0191V7.01594C18.9809 7.00548 18.7282 7.00019 18.4742 7.00019C8.55488 7.00019 0.513672 15.0591 0.513672 25.0002C0.513672 34.9413 8.55488 43.0002 18.4742 43.0002C28.0587 43.0002 35.8898 35.4762 36.4075 26.0002H33.4021Z"
    />
    <path d="M26.7352 8.78852C26.9823 8.58841 26.9823 8.21159 26.7352 8.01148L19.0493 1.78537C18.7224 1.52057 18.2346 1.75321 18.2346 2.17389L18.2346 14.6261C18.2346 15.0468 18.7224 15.2794 19.0493 15.0146L26.7352 8.78852Z" />
  </svg>
);

export default Forward10Icon;
