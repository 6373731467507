import { PageThemeCms } from '@/types/cms';
import { EventPageSlug, PageTheme } from '@/types/views/generic';

const toPageThemeMap: Record<PageThemeCms, PageTheme> = {
  [PageThemeCms.EAIR]: 'eair',
  [PageThemeCms.ESN]: 'esn',
  [PageThemeCms.EDS]: 'eds',
  [PageThemeCms.ELS]: 'els',
  [PageThemeCms.GAME]: 'game',
  [PageThemeCms.LEGAL]: 'legal',
  [PageThemeCms.MUSIC_AWARD]: 'music-award',
};

const toPageSlugMap: Partial<Record<PageThemeCms, EventPageSlug>> = {
  [PageThemeCms.EAIR]: 'air',
  [PageThemeCms.ESN]: 'star-night',
  [PageThemeCms.EDS]: 'die-sprechstunde-live',
  [PageThemeCms.ELS]: 'live-session',
};

const slugToThemeMap: Record<EventPageSlug, PageThemeCms> = {
  air: PageThemeCms.EAIR,
  'star-night': PageThemeCms.ESN,
  'die-sprechstunde-live': PageThemeCms.EDS,
  'live-session': PageThemeCms.ELS,
};

const mapToTheme = (theme?: PageThemeCms): PageTheme | null =>
  (theme && toPageThemeMap[theme]) ?? null;

export const mapToEventSlug = (theme?: PageThemeCms): EventPageSlug | null =>
  (theme && toPageSlugMap[theme]) ?? null;

export const mapSlugToTheme = (slug: EventPageSlug): PageThemeCms | null =>
  slugToThemeMap[slug] ?? null;

export default mapToTheme;
