interface YouTubeProps extends React.ComponentProps<'iframe'> {
  src: string;
  title: string;
}

const YouTube = ({ src, title, ...restProps }: YouTubeProps) => {
  const parsedYouTubeUrl = new URL(src);
  const videoId = parsedYouTubeUrl.searchParams.get('v');

  return (
    <iframe
      {...restProps}
      title={title}
      src={`https://www.youtube.com/embed/${videoId}`}
    />
  );
};

export default YouTube;
