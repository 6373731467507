import cls from 'classnames';
import { FC, MouseEvent } from 'react';

import { CardNavItemEntry } from '@/types/views/generic';

import CardNavItem from '../card-nav-item';
import styles from './styles';

interface CardNavigationProps {
  navItems: CardNavItemEntry[];
  className?: string;
  onNavItemClick?: (e: MouseEvent) => void;
}

const CardNavigation: FC<CardNavigationProps> = ({
  navItems,
  className = '',
  onNavItemClick,
}) => (
  <ul className={cls(styles.wrapper, className)}>
    {navItems.map(({ link, cardImage }) => (
      <li key={link.id} className={styles.navItem}>
        <CardNavItem link={link} cardImg={cardImage} onClick={onNavItemClick} />
      </li>
    ))}
  </ul>
);

export default CardNavigation;
