import cls from 'classnames';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import FooterCookieButton from '@/components/footer/components/cookie-button';
import SocialNavigation from '@/components/navigation/components/social-navigation';
import RichTextRenderer from '@/components/rich-text-renderer';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import {
  FooterThemeNav,
  NavItemEntry,
  NavItemEntryWithIcon,
} from '@/types/views/generic';

import Section from './components/section';
import styles from './styles';

interface FooterProps {
  isEventTheme: boolean;
  radiosNavItems: NavItemEntry[];
  appsNavItems: NavItemEntry[];
  labelNavItems: NavItemEntry[];
  mediaNavItems: NavItemEntry[];
  socialNavItems: NavItemEntryWithIcon[];
  socialThemeNavigation?: {
    title?: string;
    navItems: NavItemEntryWithIcon[];
  };
  footerThemeNavigation?: FooterThemeNav[];
  hasInvertedThemeColor?: boolean;
}

const Footer: FC<FooterProps> = React.memo(
  ({
    isEventTheme,
    radiosNavItems,
    appsNavItems,
    labelNavItems,
    mediaNavItems,
    socialNavItems,
    socialThemeNavigation,
    footerThemeNavigation,
    hasInvertedThemeColor,
  }) => {
    const { asPath } = useRouter();
    const isArticlePage = asPath.includes('/artikel/');
    const variables = useVariables();
    const pageTheme = useSelector(selectGlobalPageTheme);
    const hasCustomFooter = isArticlePage || hasInvertedThemeColor;

    const buttonProps = getThemedButtonProps(pageTheme, {
      esn: 'blue',
      eair: hasCustomFooter ? 'blue' : 'white',
      eds: 'black',
      els: 'grey-blue',
      game: 'white',
    });

    const defaultFooterNavigation = [
      {
        title: 'Radios',
        list: radiosNavItems,
        className: styles.sectionRadios,
      },
      // Cookie button is appended to below
      {
        title: 'Nützlich',
        list: labelNavItems,
        className: styles.sectionUseful,
      },
      {
        title: 'Apps',
        list: appsNavItems,
        className: styles.sectionApps,
      },
      {
        title: 'Medien',
        list: mediaNavItems,
        className: styles.sectionMedia,
      },
    ];

    const socialNavigation = isEventTheme
      ? socialThemeNavigation?.navItems || []
      : socialNavItems;

    const footerNavigation = isEventTheme
      ? footerThemeNavigation || []
      : (defaultFooterNavigation as FooterThemeNav[]);

    return (
      <div className={styles.wrapper(hasCustomFooter)}>
        <div className={styles.roundedSpace(hasCustomFooter)} />

        <footer className={styles.grid}>
          <Section
            title={
              <RichTextRenderer body={variables['subscribe-to-newsletter']} />
            }
            className={styles.sectionNewsletter(hasCustomFooter)}
            shouldUseAlternativeStyles={hasCustomFooter}
          >
            <RichTextRenderer
              className={styles.newsletterDescription(hasCustomFooter)}
              body={variables['subscribe-to-newsletter-description']}
            />

            <CustomLink href="#openNewsletter" as="#openNewsletter">
              <NormalButton
                {...buttonProps}
                text={
                  getVariablePlainText(
                    variables['subscribe-to-newsletter-cta'],
                  ) || 'Click'
                }
                size="S"
              />
            </CustomLink>
          </Section>

          {!!socialNavigation.length && (
            <Section
              title={
                socialThemeNavigation?.title ||
                getVariablePlainText(variables['follow-us-footer-copyright']) ||
                'Folge uns'
              }
              className={styles.sectionFollow}
              shouldUseAlternativeStyles={hasCustomFooter}
            >
              <SocialNavigation
                socialNavItems={socialNavigation}
                wrapperClasses={styles.socialNavWrapper}
                linkClasses={styles.socialNavItem(hasCustomFooter)}
              />

              <p className={styles.copyrightText(hasCustomFooter)}>
                {getVariablePlainText(variables['copyright-text']) ||
                  `Copyright © Energy ${new Date().getUTCFullYear()}`}
              </p>
            </Section>
          )}

          {footerNavigation.map(({ title, list, orderIndex, className }) => (
            <Section
              key={title}
              title={title}
              className={cls(styles.sectionNav, className)}
              shouldUseAlternativeStyles={hasCustomFooter}
              style={{ ...(orderIndex && { order: orderIndex }) }}
            >
              <ul>
                {list.map(({ link: { id, href, text, isExternal } }) => (
                  <li key={id} className={styles.listElement(hasCustomFooter)}>
                    <CustomLink href={href} isExternal={isExternal}>
                      {text}
                    </CustomLink>
                  </li>
                ))}
                {title === 'Nützlich' && (
                  <FooterCookieButton
                    key="cookie-settings-button"
                    hasCustomFooter={hasCustomFooter}
                  />
                )}
              </ul>
            </Section>
          ))}
        </footer>
      </div>
    );
  },
);

export default Footer;
