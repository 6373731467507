import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCurrentUserInitials,
  selectIsUserLoggedIn,
} from '@/store/slices/user';

import styles from './styles';

interface MenuButtonProps {
  isNavOpen: boolean;
}

const MenuButton: FC<MenuButtonProps> = ({ isNavOpen = true }) => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const userInitials = useSelector(selectCurrentUserInitials);

  return (
    <>
      <span className={styles.iconWrapper}>
        <svg viewBox="0 0 24 24" fill="none" className={styles.icon}>
          {isNavOpen ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.0076 6.14655C16.8125 5.95115 16.4962 5.95115 16.301 6.14655L12.0015 10.4521L7.69761 6.14818C7.50235 5.95291 7.18577 5.95291 6.9905 6.14818L6.14645 6.99224C5.95118 7.1875 5.95118 7.50408 6.14645 7.69934L10.4514 12.0043L6.15702 16.3046C5.96189 16.5 5.96189 16.8168 6.15702 17.0122L7.00049 17.8569C7.19562 18.0523 7.51198 18.0523 7.70711 17.8569L12.0026 13.5555L16.2975 17.8504C16.4928 18.0457 16.8093 18.0457 17.0046 17.8504L17.8487 17.0064C18.0439 16.8111 18.0439 16.4945 17.8487 16.2992L13.5526 12.0032L17.8511 7.69879C18.0462 7.50339 18.0462 7.18659 17.8511 6.99119L17.0076 6.14655Z"
              fill="currentColor"
            />
          ) : (
            <g>
              <path
                d="M5 6.5C5 6.22386 5.22386 6 5.5 6L18.5 6C18.7761 6 19 6.22386 19 6.5L19 7.5C19 7.77614 18.7761 8 18.5 8L5.5 8C5.22386 8 5 7.77614 5 7.5L5 6.5Z"
                fill="currentColor"
              />
              <rect
                x="5"
                y="11"
                width="14"
                height="2"
                rx="0.5"
                fill="currentColor"
              />
              <rect
                x="5"
                y="16"
                width="14"
                height="2"
                rx="0.5"
                fill="currentColor"
              />
            </g>
          )}
        </svg>
      </span>

      {isUserLoggedIn ? (
        <span className={styles.userCircle}>{userInitials}</span>
      ) : (
        <span className={styles.iconWrapper}>
          <svg viewBox="0 0 24 24" fill="none" className={styles.icon}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0055 12C14.786 12 17.0399 9.76142 17.0399 7C17.0399 4.23858 14.786 2 12.0055 2C9.22506 2 6.97107 4.23858 6.97107 7C6.97107 9.76142 9.22506 12 12.0055 12ZM2.94351 22H2.48808C2.19252 22 1.96042 21.7444 2.00566 21.4523C2.7536 16.6234 6.95842 13 12.0052 13C17.0486 13 21.1578 16.6187 21.9929 21.4429C22.044 21.738 21.8108 22 21.5113 22H21.0675H20.3992H3.51046H2.94351Z"
              fill="currentColor"
            />
          </svg>
        </span>
      )}
    </>
  );
};

export default MenuButton;
