import cls from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUserInitials } from '@/store/slices/user';

interface UserBubbleProps {
  isUserLoggedIn?: boolean;
  isNavOpen?: boolean;
  onClick?: () => void;
  className?: string;
}

const UserBubble: FC<UserBubbleProps> = ({
  isUserLoggedIn = false,
  isNavOpen = false,
  onClick = () => null,
  className = '',
}) => {
  const userInitials = useSelector(selectCurrentUserInitials);

  return (
    <button
      onClick={onClick}
      className={cls(
        'w-20 xl:w-10 h-10 rounded-full flex justify-center items-center',
        'teair:bg-air-deepBlue tesn:bg-esn-secondary-dark teds:bg-eds-gray-light tels:bg-els-blue',
        className,
      )}
    >
      <div className="h-3 w-3 xl:hidden">
        <svg viewBox="0 0 11 12" fill="none" className="text-white">
          <g>
            {isNavOpen ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 10.092 0.632 C 9.914 0.453 9.623 0.453 9.445 0.632 L 5.502 4.579 L 1.556 0.634 C 1.377 0.454 1.086 0.454 0.907 0.634 L 0.134 1.407 C -0.045 1.585 -0.045 1.876 0.134 2.056 L 4.081 6.003 L 0.143 9.947 C -0.034 10.124 -0.034 10.415 0.143 10.595 L 0.916 11.368 C 1.096 11.547 1.387 11.547 1.564 11.368 L 5.503 7.425 L 9.441 11.363 C 9.622 11.541 9.911 11.541 10.09 11.363 L 10.863 10.589 C 11.043 10.41 11.043 10.121 10.863 9.941 L 6.924 6.002 L 10.866 2.055 C 11.044 1.876 11.044 1.584 10.866 1.406 L 10.092 0.632 Z"
                fill="currentColor"
              />
            ) : (
              <>
                <rect
                  x="0"
                  y="0"
                  width="11"
                  height="2"
                  rx="0.5"
                  fill="currentColor"
                />
                <rect
                  x="0"
                  y="5"
                  width="11"
                  height="2"
                  rx="0.5"
                  fill="currentColor"
                />
                <rect
                  x="0"
                  y="10"
                  width="11"
                  height="2"
                  rx="0.5"
                  fill="currentColor"
                />
              </>
            )}
          </g>
        </svg>
      </div>

      <div className="ml-4 xl:ml-0 text-body2 font-semibold teair:text-white">
        {isUserLoggedIn ? (
          userInitials
        ) : (
          <svg viewBox="0 0 11 12" fill="none" className="text-white h-5 w-5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M 5.503 6 C 7.032 6 8.272 4.769 8.272 3.25 C 8.272 1.731 7.032 0.5 5.503 0.5 C 3.974 0.5 2.734 1.731 2.734 3.25 C 2.734 4.769 3.974 6 5.503 6 Z M 0.519 11.5 L 0.268 11.5 C 0.106 11.5 -0.022 11.359 0.003 11.198 C 0.414 8.542 2.727 6.55 5.503 6.55 C 8.276 6.55 10.536 8.541 10.996 11.194 C 11.024 11.356 10.896 11.5 10.731 11.5 L 10.487 11.5 L 10.12 11.5 L 0.831 11.5 L 0.519 11.5 Z"
              fill="currentColor"
            />
          </svg>
        )}
      </div>
    </button>
  );
};

export default UserBubble;
