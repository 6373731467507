import React, { FC } from 'react';

interface CrossIconProps {
  className?: string;
}

const CrossIcon: FC<CrossIconProps> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0077 6.14655C16.8126 5.95115 16.4962 5.95115 16.3011 6.14655L12.0016 10.4521L7.69769 6.14818C7.50242 5.95291 7.18584 5.95291 6.99058 6.14818L6.14652 6.99224C5.95126 7.1875 5.95126 7.50408 6.14652 7.69934L10.4515 12.0043L6.15709 16.3046C5.96197 16.5 5.96197 16.8168 6.15709 17.0122L7.00057 17.8569C7.19569 18.0523 7.51206 18.0523 7.70718 17.8569L12.0026 13.5555L16.2976 17.8504C16.4928 18.0457 16.8094 18.0457 17.0047 17.8504L17.8487 17.0064C18.044 16.8111 18.044 16.4945 17.8487 16.2992L13.5527 12.0032L17.8512 7.69879C18.0463 7.50339 18.0463 7.18659 17.8512 6.99119L17.0077 6.14655Z"
      fill="currentColor"
    />
  </svg>
);

export default CrossIcon;
