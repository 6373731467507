interface BrightcoveProps extends React.ComponentProps<'iframe'> {
  src: string;
  title: string;
}

const Brightcove = ({ src, title, ...iframeProps }: BrightcoveProps) => (
  <iframe {...iframeProps} title={title} src={src} />
);

export default Brightcove;
