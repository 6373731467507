import cls from 'classnames';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';

import WelcomeAd from '@/components/advertisement/components/welcome-ad';
import ChannelSwitcherMobile from '@/components/channel-switcher/mobile';
import Footer from '@/components/footer';
import Navbar from '@/components/navbar';
import PlayerBarMobile from '@/components/player-bar/mobile';
import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';
import { selectIsWelcomeAdVisible } from '@/store/slices/ads';
import { NavigationEntry } from '@/types/views/generic';

import ChannelSwitcherDesktopOverlay from '../channel-switcher-desktop-overlay';

interface MainLayoutProps {
  navigation: NavigationEntry;
  isNavOpen: boolean;
  isChannelSwitcherOpen: boolean;
  className?: string;
  onNavToggle: () => void;
  onChannelSwitcherToggle: () => void;
  deactivateAdSlots?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({
  navigation,
  isNavOpen,
  isChannelSwitcherOpen,
  className = '',
  children,
  onNavToggle,
  onChannelSwitcherToggle,
  deactivateAdSlots,
}) => {
  const navbarPlayerBarButtonRef = useRef<HTMLButtonElement | null>(null);
  const channelSwitchRef = useRef<HTMLDivElement | null>(null);
  const playerBarMobileRef = useRef<HTMLDivElement | null>(null);
  const channelSwitchMobileRef = useRef<HTMLDivElement | null>(null);
  const smallPlayerBarVolumeButtonRef = useRef<HTMLDivElement | null>(null);
  const isWelcomeAdVisible = useSelector(selectIsWelcomeAdVisible);

  const handleClickOutside = () => {
    if (isChannelSwitcherOpen) {
      onChannelSwitcherToggle();
    }
  };

  useOutsideClickNotifier(
    [
      navbarPlayerBarButtonRef,
      channelSwitchRef,
      playerBarMobileRef,
      channelSwitchMobileRef,
      smallPlayerBarVolumeButtonRef,
    ],
    handleClickOutside,
  );

  return (
    <>
      <Navbar
        navigation={navigation}
        isNavOpen={isNavOpen}
        isChannelSwitcherOpen={isChannelSwitcherOpen}
        onNavToggle={onNavToggle}
        onChannelSwitcherToggle={onChannelSwitcherToggle}
        className={className}
        playerBarButtonRef={navbarPlayerBarButtonRef}
        volumeButtonRef={smallPlayerBarVolumeButtonRef}
      />

      <ChannelSwitcherDesktopOverlay
        channelSwitchRef={channelSwitchRef}
        isChannelSwitcherOpen={isChannelSwitcherOpen}
        onChannelSwitcherToggle={onChannelSwitcherToggle}
      />

      <div
        className={cls(
          'fixed flex flex-col-reverse bottom-0 left-0 w-full z-40 md:hidden',
          isChannelSwitcherOpen && 'h-full bg-black',
        )}
      >
        <PlayerBarMobile
          onClick={onChannelSwitcherToggle}
          hidden={isWelcomeAdVisible || isChannelSwitcherOpen}
          ref={playerBarMobileRef}
        />

        {isChannelSwitcherOpen && (
          <ChannelSwitcherMobile
            onClose={onChannelSwitcherToggle}
            ref={channelSwitchMobileRef}
          />
        )}
      </div>

      <WelcomeAd className="hidden md:block" />

      <WelcomeAd isMobile className="md:hidden" />

      <div className={cls(deactivateAdSlots && 'max-w-full')}>{children}</div>

      <Footer {...navigation} isEventTheme={false} />
    </>
  );
};

export default MainLayout;
