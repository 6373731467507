import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPlayerCurrentAudio,
  selectPlayerCurrentChannel,
  selectPlayerIsPaused,
  selectPlayerIsPlaying,
  setCurrentChannelId,
} from '@/store/slices/player';
import { Channel as ChannelT, ChannelGroupType } from '@/types/views/generic';

import Switch from './switch';

interface ChannelProps {
  channel: ChannelT;
  imageOverride?: string;
  channelGroupType: ChannelGroupType;
  onClick: () => void;
}

const Channel: FC<ChannelProps> = ({
  channel,
  imageOverride,
  onClick,
  channelGroupType,
}) => {
  const currentChannel = useSelector(selectPlayerCurrentChannel);
  const currentAudio = useSelector(selectPlayerCurrentAudio);
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const isPaused = useSelector(selectPlayerIsPaused);

  const dispatch = useDispatch();
  const switchChannel = () => {
    dispatch(setCurrentChannelId(channel.id));
    onClick();
  };

  const isActive =
    currentChannel?.id === channel.id &&
    (isPaused || isPlaying) &&
    !currentAudio;

  const imageUrl = imageOverride || channel.logoUrl;

  const isStation = channelGroupType === ChannelGroupType.Station;

  return (
    <Switch
      textPrimary={channel.name}
      textSecondary={channel.shortDescription}
      isActive={isActive}
      imageUrl={imageUrl}
      onClick={switchChannel}
      channelId={channel.id}
      hasVersionXL={isStation}
      hasRoundedLogo={isStation}
    />
  );
};

export default Channel;
