import cls from 'classnames';
import { useSelector } from 'react-redux';

import { goToProfile, goToProfileTickets, logout } from '@/lib/auth';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { PageTheme } from '@/types/views/generic';

const linkClassName = cls(
  'block py-2 xl:px-4 text-body1 xl:text-body2 w-full text-left xl:text-white',
);

const buttonHoverStyles: Partial<Record<PageTheme, string>> = {
  eds: 'hover:text-eds-pink',
  esn: 'hover:text-esn-secondary-light',
  eair: 'hover:text-air-coral',
  els: 'hover:text-els-gold-dark',
};

const EventNav = () => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const hoverStyles = pageTheme && buttonHoverStyles[pageTheme];

  return (
    <ul className="xl:py-2">
      <li>
        <button
          onClick={() => goToProfileTickets()}
          className={cls(hoverStyles, linkClassName)}
        >
          Meine Tickets
        </button>
      </li>

      <li>
        <button
          onClick={() => goToProfile()}
          className={cls(hoverStyles, linkClassName)}
        >
          Profilübersicht
        </button>
      </li>

      <li className="hidden xl:block">
        <button
          onClick={logout}
          className={cls(
            'text-white text-overline underline w-full py-2 px-4 text-left',
            hoverStyles,
          )}
        >
          Abmelden
        </button>
      </li>
    </ul>
  );
};

export default EventNav;
