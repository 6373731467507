import { useState } from 'react';

const useNavigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => setIsNavOpen((prevState) => !prevState);

  return {
    isNavOpen,
    setIsNavOpen,
    toggleNav,
  };
};

export default useNavigation;
