import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import usePlayer from '@/lib/hooks/use-player';
import { useStoreDispatch } from '@/store';
import {
  selectPlayerIsLoading,
  selectPlayerIsPlaying,
  setCurrentChannelId,
} from '@/store/slices/player';

import PlayPauseButton from './play-pause-button';

interface RadioPlayPauseButtonProps {
  isActive?: boolean;
  playIcon?: JSX.Element;
  pauseIcon?: JSX.Element;
  stopPropagation?: boolean;

  /*
    ID of the channel that will be set as current after clicking the button.
    Don't pass the prop if current channel id from store should not change.
  */
  channelId?: string | null;
  className?: string;
}

const RadioPlayPauseButton: FC<RadioPlayPauseButtonProps> = ({
  isActive = true,
  playIcon,
  pauseIcon,
  className = '',
  stopPropagation = false,
  channelId,
}) => {
  const player = usePlayer();
  const isPlaying = useSelector(selectPlayerIsPlaying) && isActive;
  const isLoading = useSelector(selectPlayerIsLoading) && isActive;
  const dispatch = useStoreDispatch();

  const onClick = (event: React.MouseEvent) => {
    stopPropagation && event.stopPropagation();

    if (isPlaying) {
      player?.stop();
    } else {
      player?.play();

      if (channelId) {
        dispatch(setCurrentChannelId(channelId));
      }
    }
  };

  return (
    <PlayPauseButton
      isLoading={isLoading}
      className={className}
      isPlaying={isPlaying}
      onClick={onClick}
      pauseIcon={pauseIcon}
      playIcon={playIcon}
    />
  );
};

export default RadioPlayPauseButton;
