import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';
import { screens } from 'tailwind/theme';

import UserBubble from '@/components/assets/icons/user-bubble';
import EventNav from '@/components/navigation/components/event-nav';
import Popover from '@/components/popover';
import { login } from '@/lib/auth';

const triangleCss = css`
  @media (min-width: ${screens.xl}) {
    margin-left: -1.75rem;
  }

  @media (min-width: ${screens['2xl']}) {
    margin-left: 0.75rem;
  }
`;

interface UserMenuProps {
  isUserLoggedIn: boolean;
  isNavOpen: boolean;
  onNavToggle: () => void;
}

const UserMenu: FC<UserMenuProps> = ({
  isUserLoggedIn,
  isNavOpen,
  onNavToggle,
}) =>
  isUserLoggedIn ? (
    <>
      <Popover
        className="mr-4 ml-8 hidden xl:block"
        contentClassName="w-52 xl:right-0 2xl:left-0"
        renderContent={() => <EventNav />}
        triangleClassName={cls(
          'xl:ml-0 xl:relative xl:-right-full 2xl:right-0',
          triangleCss,
        )}
      >
        <button className="flex justify-center items-center">
          <UserBubble isUserLoggedIn={isUserLoggedIn} isNavOpen={isNavOpen} />
        </button>
      </Popover>

      <div className="xl:hidden">
        <UserBubble
          isUserLoggedIn={isUserLoggedIn}
          onClick={onNavToggle}
          isNavOpen={isNavOpen}
        />
      </div>
    </>
  ) : (
    <>
      <UserBubble
        onClick={login}
        className="hidden xl:flex items-center mr-4 ml-8"
      />

      <div className="xl:hidden">
        <UserBubble onClick={onNavToggle} isNavOpen={isNavOpen} />
      </div>
    </>
  );

export default UserMenu;
