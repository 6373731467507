import React, { FC } from 'react';
import theme, { colors } from 'tailwind/theme';

interface IconProps {
  className?: string;
}

interface ArrowRightCircledFilledIconProps extends IconProps {
  backgroundClassName?: string;
  arrowClassName?: string;
}

export const ArrowRightCircledFilledIcon: FC<ArrowRightCircledFilledIconProps> =
  ({ className, backgroundClassName, arrowClassName }) => (
    <svg className={className} width="32" viewBox="0 0 32 32" fill="none">
      <ellipse
        cx="16"
        cy="16"
        rx="16"
        ry="16"
        transform="rotate(-90 16 16)"
        fill={colors.red['light-darker']}
        className={backgroundClassName}
      />
      <path
        d="M9.77821 15.7218C9.77821 15.4456 10.0021 15.2218 10.2782 15.2218L19.2438 15.2218L15.2508 11.2288C15.055 11.0331 15.0556 10.7155 15.252 10.5205L15.6469 10.1284C15.8423 9.93432 16.158 9.93487 16.3527 10.1296L21.8691 15.646C22.0644 15.8413 22.0644 16.1579 21.8691 16.3531L16.354 21.8682C16.1587 22.0635 15.8421 22.0635 15.6469 21.8682L15.257 21.4784C15.0619 21.2832 15.0617 20.9669 15.2567 20.7716L19.2438 16.7773L10.2782 16.7773C10.0021 16.7773 9.77821 16.5535 9.77821 16.2773L9.77821 15.7218Z"
        fill={colors.black.light}
        className={arrowClassName}
      />
    </svg>
  );

interface ArrowRightCircledIconProps extends IconProps {
  arrowClassName?: string;
  circleClassName?: string;
}

export const ArrowRightCircledIcon: FC<ArrowRightCircledIconProps> = ({
  className,
  arrowClassName,
  circleClassName,
}) => (
  <svg
    className={className}
    width="63"
    height="auto"
    viewBox="0 0 63 63"
    fill="none"
  >
    <path
      className={circleClassName}
      d="M31.5 0.50001C48.6208 0.500012 62.5 14.3792 62.5 31.5C62.5 48.6208 48.6208 62.5 31.5 62.5C14.3792 62.5 0.500003 48.6208 0.500006 31.5C0.50001 14.3792 14.3792 0.500009 31.5 0.50001Z"
    />
    <path
      className={arrowClassName}
      d="M21 31.1417C21 30.5894 21.4477 30.1417 22 30.1417L36.9731 30.1417L30.3444 23.5037C29.9535 23.1123 29.9547 22.478 30.3469 22.088L30.7924 21.645C31.1834 21.2562 31.8154 21.2573 32.2051 21.6475L41.2944 30.7494C41.6842 31.1398 41.6842 31.7722 41.2944 32.1626L32.2076 41.262C31.817 41.6532 31.183 41.6532 30.7924 41.262L30.3544 40.8234C29.9648 40.4332 29.9645 39.8013 30.3537 39.4108L36.9731 32.7703L22 32.7703C21.4477 32.7703 21 32.3226 21 31.7703L21 31.1417Z"
    />
  </svg>
);

interface ArrowIconProps extends IconProps {
  arrowClassName?: string;
  circleClassName?: string;
  fill?: string;
}

export const ArrowIcon: FC<ArrowIconProps> = ({
  className = '',
  arrowClassName = '',
  fill = theme.colors.white,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M15.6776 12.3426C15.8588 12.1501 15.8588 11.8499 15.6776 11.6574L15.4635 11.4298L9.74272 5.33661C9.56425 5.14653 9.26968 5.1255 9.06601 5.28829L8.42289 5.80237C8.19497 5.98455 8.17083 6.32239 8.37053 6.53513L13.1791 11.6578C13.3597 11.8502 13.3597 12.1498 13.1791 12.3422L8.37053 17.4649C8.17083 17.6776 8.19497 18.0154 8.42289 18.1976L9.06601 18.7117C9.26968 18.8745 9.56425 18.8535 9.74272 18.6634L15.4635 12.5702L15.6776 12.3426Z"
      className={arrowClassName}
      fill={fill}
    />
  </svg>
);
