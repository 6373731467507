import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import useAudioPlayer from '@/lib/hooks/use-audio-player';
import { selectAudioPlayerIsPlaying } from '@/store/slices/player';

import PlayPauseButton from './play-pause-button';

interface AudioPlayPauseButtonProps {
  isActive?: boolean;
  playIcon?: JSX.Element;
  pauseIcon?: JSX.Element;
  stopPropagation?: boolean;
  className?: string;
}

const AudioPlayPauseButton: FC<AudioPlayPauseButtonProps> = ({
  isActive = true,
  playIcon,
  pauseIcon,
  className = '',
  stopPropagation = false,
}) => {
  const { onResume, onPause } = useAudioPlayer();
  const isPlaying = useSelector(selectAudioPlayerIsPlaying) && isActive;

  const onClick = (event: React.MouseEvent) => {
    stopPropagation && event.stopPropagation();

    if (isPlaying) {
      onPause();
    } else {
      onResume();
    }
  };

  return (
    <PlayPauseButton
      className={className}
      isPlaying={isPlaying}
      onClick={onClick}
      pauseIcon={pauseIcon}
      playIcon={playIcon}
    />
  );
};

export default AudioPlayPauseButton;
