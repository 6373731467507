import { INLINES } from '@contentful/rich-text-types';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import newsletterError from '@/assets/form-images/form-error.svg';
import newsletterSuccess from '@/assets/form-images/form-success.svg';
import NormalButton from '@/components/button/normal';
import Image from '@/components/image';
import RichTextRenderer, {
  renderInlineHyperlink,
} from '@/components/rich-text-renderer';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { ImageAsset } from '@/types/views/generic';

import styles from './styles';

interface ThirdStepProps {
  onButtonClick: () => void;
  onPrevButtonClick: () => void;
  hasError: boolean;
  emailExists: boolean;
  newsletterSuccessImage?: ImageAsset;
  newsletterErrorImage?: ImageAsset;
}

const ThirdStep: FC<ThirdStepProps> = ({
  onButtonClick,
  onPrevButtonClick,
  hasError,
  emailExists,
  newsletterSuccessImage,
  newsletterErrorImage,
}) => {
  const variables = useVariables();
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme);
  const isEsnTheme = pageTheme === 'esn';

  const title = getVariablePlainText(
    variables[
      hasError
        ? 'newsletter-email-error-title'
        : 'newsletter-success-popup-title'
    ],
  );
  const successTextSlug = isEsnTheme
    ? 'newsletter-success-popup-text-esn'
    : 'newsletter-success-popup-text';
  const text =
    variables[hasError ? 'newsletter-email-error-text' : successTextSlug];
  const buttonText = getVariablePlainText(
    variables[
      hasError
        ? 'newsletter-email-error-cta-text'
        : 'newsletter-success-popup-back'
    ],
  );
  const cancelBtnText = getVariablePlainText(
    variables['newsletter-email-error-cancel-text'],
  );
  const emailExistsTitle = getVariablePlainText(
    variables['newsletter-email-exists-error-title'],
  );
  const emailExistsText = variables['newsletter-email-exists-error-text'];
  const emailExistsButtonText = getVariablePlainText(
    variables['newsletter-email-exists-error-cta-text'],
  );

  const successImageSrc = newsletterSuccessImage?.src || newsletterSuccess.src;
  const errorImageSrc = newsletterErrorImage?.src || newsletterError.src;

  return (
    <div className={styles.content}>
      <figure className={styles.imageContainer(isEsnTheme)}>
        <Image
          src={hasError ? errorImageSrc : successImageSrc}
          alt={getVariablePlainText(text) || ''}
          className={styles.image}
          objectFit="scale-down"
        />
      </figure>

      <h2 className={styles.heading}>
        {emailExists ? emailExistsTitle : title}
      </h2>

      <p className={styles.description}>
        <RichTextRenderer
          className={styles.description}
          body={emailExists ? emailExistsText : text}
          options={{
            renderNode: {
              [INLINES.HYPERLINK]: renderInlineHyperlink(styles.description),
            },
          }}
        />
      </p>

      <NormalButton
        {...buttonProps}
        text={emailExists ? emailExistsButtonText : buttonText}
        size="L"
        onClick={emailExists ? onPrevButtonClick : onButtonClick}
      />

      {hasError && !emailExists && (
        <div className={styles.btnContainer}>
          <button
            type="button"
            onClick={onPrevButtonClick}
            className={styles.cancelBtn}
          >
            {cancelBtnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default ThirdStep;
