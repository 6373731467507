import cls from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import useNavbar from '@/components/layout/use-navbar';
import EventNavigation from '@/components/navigation/components/event-navigation';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { selectIsUserLoggedIn } from '@/store/slices/user';
import { NavigationEntry, ThemeSettings } from '@/types/views/generic';

import Navigation from '../navigation';
import NavbarEnergyLogo from './components/navbar-energy-logo';
import ThemeLogo from './components/theme-logo';
import UserMenu from './components/user-menu';
import styles from './styles';

interface EventNavbarProps {
  navigation: NavigationEntry;
  isNavOpen: boolean;
  className?: string;
  onNavToggle: () => void;
  themeSettings?: ThemeSettings;
}

const EventNavbar: FC<EventNavbarProps> = ({
  navigation,
  isNavOpen,
  className = '',
  onNavToggle,
  themeSettings,
}) => {
  const { asPath } = useRouter();
  const { isNavbarVisible } = useNavbar();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const pageTheme = useSelector(selectGlobalPageTheme);
  const isLiveSessionEvent = pageTheme === 'els';

  return (
    <div id="navbar" className={cls(styles.wrapper(isNavOpen), className)}>
      <div className={styles.contentContainer}>
        <CustomLink
          href={`/${themeSettings?.urlSlug || ''}`}
          className={styles.energyLogoWrapper(
            isNavbarVisible,
            isLiveSessionEvent,
          )}
        >
          {themeSettings && (
            <ThemeLogo isMobile={false} themeSettings={themeSettings} />
          )}

          {themeSettings && (
            <ThemeLogo isMobile themeSettings={themeSettings} />
          )}
        </CustomLink>

        {themeSettings && (
          <NavbarEnergyLogo
            wrapperClassName="flex xl:hidden"
            dividerClassName="tesn:bg-esn-secondary teds:bg-white"
            themeSettings={themeSettings}
          />
        )}

        {themeSettings?.eventNav && (
          <EventNavigation
            navItems={themeSettings.eventNav}
            currentAsPath={asPath}
            className="hidden xl:block xl:tels:pl-40 2xl:tels:pl-0"
          />
        )}

        <div className={styles.iconsWrapper}>
          {themeSettings?.locationTimeImage && (
            <div className="items-center hidden xl:flex">
              <Image
                src={themeSettings.locationTimeImage.src}
                useIntrinsicSize
                width={themeSettings.locationTimeImage.width}
                height={themeSettings.locationTimeImage.height}
                alt={`${themeSettings.title} - Location`}
              />
            </div>
          )}

          <UserMenu
            isUserLoggedIn={isUserLoggedIn}
            isNavOpen={isNavOpen}
            onNavToggle={onNavToggle}
          />

          {themeSettings && (
            <NavbarEnergyLogo
              wrapperClassName="hidden xl:flex"
              dividerClassName="tesn:bg-red-light-darker teds:bg-white tels:bg-red-light-darker"
              themeSettings={themeSettings}
            />
          )}
        </div>
      </div>

      {isNavOpen && (
        <Navigation
          className={styles.navigation}
          navigation={navigation}
          onNavItemClick={onNavToggle}
          isEventTheme
          isUserLoggedIn={isUserLoggedIn}
          navigationLowerImage={themeSettings?.locationTimeImage}
        />
      )}
    </div>
  );
};

export default EventNavbar;
