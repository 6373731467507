import cls from 'classnames';
import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import FormElement, { InputVariant } from '..';
import styles from '../styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  showLabel?: boolean;
  variant?: InputVariant;
  disabled?: boolean;
  containerClassName?: string;
  className?: string;
  helperText?: string;
  error?: string;
  submitFailed?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      showLabel,
      variant = 'newsletter',
      disabled,
      containerClassName,
      className,
      helperText,
      error,
      submitFailed,
      ...restProps
    },
    ref,
  ) => {
    const [blur, setBlur] = useState(false);
    const hasError = !!error;

    const onBlur = () => setBlur(true);

    useEffect(() => {
      if (submitFailed) setBlur(true);
    }, [submitFailed]);

    return (
      <FormElement
        id={id}
        label={label}
        showLabel={showLabel}
        className={containerClassName}
        helperText={helperText}
        error={error}
        blur={blur}
      >
        <input
          {...restProps}
          ref={ref}
          id={id}
          disabled={disabled}
          className={cls(styles.input(variant, hasError, blur), className)}
          onBlur={onBlur}
        />
      </FormElement>
    );
  },
);

export default Input;
