import cls from 'classnames';

const styles = {
  wrapper: 'flex flex-col ml-2 lg:ml-0',
  navItemLink: (isActive: boolean) =>
    cls(
      isActive
        ? 'text-red tesn:text-esn-secondary-light teds:text-eds-pink tels:text-els-gold-dark'
        : 'text-white teair:text-air-deepBlue',
      'text-nav lg:uppercase hover:text-red-medium hover:tesn:text-exn-neonViolet hover:teds:text-eds-pink',
    ),
  navItem: 'relative mb-4 lg:mb-0',
  bullet:
    'absolute w-2 h-2 2xl:w-4 2xl:h-4 bg-red tesn:bg-esn-secondary-light teds:bg-eds-pink tels:bg-els-gold-dark rounded-full -left-3 2xl:-left-6 top-1/2 transform -translate-y-1/2',
};

export default styles;
