import { FC } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import ProfileIcon from '@/components/icons/profile-icon';
import RichTextRenderer from '@/components/rich-text-renderer';
import { goToProfile, login } from '@/lib/auth';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import useVariables from '@/lib/hooks/use-variables';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { selectIsUserLoggedIn } from '@/store/slices/user';

const LoginButton: FC = () => {
  const variables = useVariables();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme, { game: 'white' });

  return isUserLoggedIn ? (
    <NormalButton
      {...buttonProps}
      text={
        <div className="flex items-center">
          <ProfileIcon className="flex-shrink-0 mr-2" />
          <RichTextRenderer body={variables['nav-go-to-profile-button']} />
        </div>
      }
      className="font-semibold"
      onClick={() => goToProfile()}
    />
  ) : (
    <NormalButton
      {...buttonProps}
      text={<RichTextRenderer body={variables['nav-login-button']} />}
      className="font-semibold"
      onClick={login}
    />
  );
};

export default LoginButton;
