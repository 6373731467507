import { css } from '@emotion/css';
import cls from 'classnames';
import { FC, RefObject } from 'react';

const triangleClass = (sliderPosition: 'top' | 'bottom') =>
  sliderPosition === 'bottom'
    ? css`
        border-bottom-color: white;
      `
    : css`
        border-top-color: white;
      `;

interface SliderBoxProps {
  trackRef: RefObject<HTMLDivElement>;
  value: number;
  sliderPosition?: 'top' | 'bottom';
}

const SliderBox: FC<SliderBoxProps> = ({
  trackRef,
  value,
  sliderPosition = 'bottom',
}) => (
  <div className="py-4 px-3 h-full bg-white rounded-2 drop-shadow-[4px_4px_16px_rgba(0,3,49,0.1)]">
    <div
      className={cls(
        'absolute w-4 h-0 left-1/2 transform -translate-x-1/2  border-transparent border-8',
        sliderPosition === 'bottom' ? 'bottom-full' : 'top-full',
        triangleClass(sliderPosition),
      )}
    />

    <button className="relative block w-4 h-full">
      <div
        className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1 h-full bg-black-light teair:bg-air-deepBlue"
        ref={trackRef}
      >
        <div
          className="absolute left-1/2 w-4 h-4 rounded-full bg-red teair:bg-air-coral transform -translate-x-1/2 translate-y-1/2 pointer-events-none"
          style={{
            bottom: `${value}%`,
          }}
        />
      </div>
    </button>
  </div>
);

export default SliderBox;
