import { RefObject, useEffect, useRef, useState } from 'react';

function useOnScreen(ref: RefObject<HTMLElement>): boolean {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      {
        threshold: 0.35,
      },
    );
  }, []);

  useEffect(() => {
    if (observerRef.current && ref.current)
      observerRef.current.observe(ref.current);

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export default useOnScreen;
