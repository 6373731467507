import cls from 'classnames';
import { FC, RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';

import useNavbar from '@/components/layout/use-navbar';
import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';
import usePageTheme from '@/lib/hooks/use-page-theme';
import { selectIsUserLoggedIn } from '@/store/slices/user';
import { NavigationEntry } from '@/types/views/generic';

import Navigation from '../navigation';
import PlayerBarDesktop from '../player-bar/desktop';
import EnergyLogoLink from './components/energy-logo-link';
import Icons from './components/icons';
import styles from './styles';

export interface NavbarProps {
  navigation: NavigationEntry;
  isNavOpen: boolean;
  className?: string;
  onNavToggle: () => void;
  isChannelSwitcherOpen: boolean;
  onChannelSwitcherToggle: () => void;
  playerBarButtonRef: RefObject<HTMLButtonElement> | null;
  volumeButtonRef?: RefObject<HTMLDivElement>;
}

const Navbar: FC<NavbarProps> = ({
  navigation,
  isNavOpen,
  isChannelSwitcherOpen,
  className = '',
  onNavToggle,
  onChannelSwitcherToggle,
  playerBarButtonRef,
  volumeButtonRef,
}) => {
  const navbarContainerRef = useRef<HTMLDivElement | null>(null);
  const navbarRef = useRef<HTMLDivElement | null>(null);

  const { isNavbarVisible } = useNavbar();
  const { isEventTheme } = usePageTheme();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const closeNav = () => isNavOpen && onNavToggle();

  const handleClickOutside = () => {
    if (isNavOpen) {
      onNavToggle();
    }
  };

  useOutsideClickNotifier([navbarContainerRef, navbarRef], handleClickOutside);

  return (
    <div id="navbar" className={cls(styles.wrapper(isNavOpen), className)}>
      <div className={styles.contentContainer} ref={navbarContainerRef}>
        <EnergyLogoLink
          isEventTheme={isEventTheme}
          isNavbarVisible={isNavbarVisible}
          onNavClose={closeNav}
        />

        <div className={styles.playerBarDesktopWrapper}>
          <PlayerBarDesktop
            ref={playerBarButtonRef}
            smallBarVolumeButtonRef={volumeButtonRef}
            isChannelSwitcherOpen={isChannelSwitcherOpen}
            onChannelSwitcherToggle={onChannelSwitcherToggle}
            small
          />
        </div>

        <div className={styles.iconsWrapper}>
          <Icons
            navItems={navigation.fastIconNavItems}
            isNavOpen={isNavOpen}
            onNavToggle={onNavToggle}
          />
        </div>
      </div>

      {isNavOpen && (
        <Navigation
          ref={navbarRef}
          className={styles.navigation}
          navigation={navigation}
          onNavItemClick={onNavToggle}
          isUserLoggedIn={isUserLoggedIn}
        />
      )}
    </div>
  );
};

export default Navbar;
