import cls from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';

import CustomLink from '@/components/custom-link';
import Icon from '@/components/icon';
import ContentfulIcon from '@/components/icons/contentful-icon';
import MenuButton from '@/components/navbar/components/menu-button';
import { NavItemEntryWithIcon } from '@/types/views/generic';

import styles from './styles';

interface IconsProps {
  navItems: NavItemEntryWithIcon[];
  isNavOpen: boolean;
  onNavToggle: () => void;
  className?: string;
}

const Icons: FC<IconsProps> = ({
  navItems,
  isNavOpen,
  onNavToggle,
  className = '',
}) => {
  const { asPath } = useRouter();

  const closeNav = () => isNavOpen && onNavToggle();

  return (
    <ul className={cls(styles.iconsContainer, className)}>
      {navItems?.map(({ link, icon }) => {
        const navItemPath = link.as || link.href;
        const isActive = navItemPath === asPath;

        return (
          <li key={link.id} className={styles.iconItem(isActive)}>
            <CustomLink {...link} onClick={closeNav} className="inline-block">
              <ContentfulIcon
                src={icon.src}
                alt={icon.description}
                className={styles.quickNavIcon}
              />
            </CustomLink>

            <span className={styles.divider} />
          </li>
        );
      })}

      <li className={styles.navigationIconItem}>
        <Icon
          element={<MenuButton isNavOpen={isNavOpen} />}
          isButton
          isCircle
          className={styles.iconButton}
          onClick={onNavToggle}
        />
      </li>
    </ul>
  );
};

export default Icons;
