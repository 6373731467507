import cls from 'classnames';
import { FC, RefObject } from 'react';

import EnergyLogo from '@/components/assets/energy-logo';
import { ChevronDownIcon } from '@/components/icons/chevron-icon';
import PauseIcon from '@/components/icons/pause-icon';
import PlayIcon from '@/components/icons/play-icon';
import RadioPlayPauseButton from '@/components/play-pause-button/radio-play-pause-button';
import VolumeButton from '@/components/volume-button';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

const commonClassNames =
  'fixed flex items-center bg-air-deepBlue rounded-10 p-4 px-6 z-50 tesn:bg-esn-secondary-dark teds:bg-black-light teair:bg-air-mediumBlue';

interface FloatingPlayerProps {
  onToggleChannelSwitcher: () => void;
  isChannelSwitcherOpen: boolean;
  floatingPlayerRef: RefObject<HTMLDivElement>;
  floatingPlayerMobileRef: RefObject<HTMLDivElement>;
}

const FloatingPlayer: FC<FloatingPlayerProps> = ({
  onToggleChannelSwitcher,
  isChannelSwitcherOpen,
  floatingPlayerRef,
  floatingPlayerMobileRef,
}) => {
  const variables = useVariables();

  const floatingButtonContent = () => (
    <>
      <div className="pr-3 border-r border-air-lila teds:border-white border-opacity-40">
        <EnergyLogo noText className="h-8 teair:fill-white fill-red" />
      </div>

      <div className="ml-4 flex items-center">
        <div className="mr-4 hidden md:block">
          <VolumeButton className="h-8 w-8" sliderPosition="top" />
        </div>

        <RadioPlayPauseButton
          className="text-white h-8 w-8"
          playIcon={
            <PlayIcon className="w-full h-full text-white teair:fill-air-deepBlue" />
          }
          pauseIcon={
            <PauseIcon className="w-full h-full text-white teair:fill-air-deepBlue" />
          }
          stopPropagation
        />
      </div>

      <div className="hidden md:block ml-4">
        <button
          className="text-white bg-air-lila bg-opacity-40 rounded-full px-4 py-1 pl-6 flex items-center tesn:bg-esn-secondary teds:bg-eds-gray-light font-semibold"
          onClick={onToggleChannelSwitcher}
        >
          {getVariablePlainText(variables['choose-a-channel'])}

          <ChevronDownIcon
            className={cls(
              'ml-1 transform transition-transform',
              isChannelSwitcherOpen ? 'rotate-0' : 'rotate-180',
            )}
          />
        </button>
      </div>
    </>
  );

  return (
    <>
      <div
        className={cls(
          commonClassNames,
          'md:hidden bottom-6 right-5',
          isChannelSwitcherOpen && 'hidden',
        )}
        onClick={onToggleChannelSwitcher}
        ref={floatingPlayerMobileRef}
      >
        {floatingButtonContent()}
      </div>

      <div
        className={cls(
          commonClassNames,
          'hidden md:flex bottom-8 right-12 pl-6 drop-shadow-[4px_4px_16px_rgba(0,3,49,0.1)]',
        )}
        ref={floatingPlayerRef}
      >
        {floatingButtonContent()}
      </div>
    </>
  );
};

export default FloatingPlayer;
