import { css } from '@emotion/css';
import cls from 'classnames';
import { ReactElement } from 'react';

import Marquee from '@/components/marquee';
import Share from '@/components/share';
import TagChip from '@/components/tag-chip';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import { Tag } from '@/types/views/generic';

interface PlayerProps {
  onClose: () => void;
  playerBgImage: ReactElement;
  actionButton: ReactElement;
  countdowns: ReactElement;
  cover: ReactElement;
  fullShareUrl?: string;
  title?: string;
  primaryPlayingTitle?: string;
  secondaryPlayingTitle?: string;
  moderatorTags?: Tag[];
  isPlaying: boolean;
  isPaused: boolean;
}

const Player = ({
  onClose,
  playerBgImage,
  countdowns,
  actionButton,
  cover,
  title,
  primaryPlayingTitle,
  secondaryPlayingTitle,
  fullShareUrl,
  moderatorTags,
  isPlaying,
  isPaused,
}: PlayerProps) => {
  const { isMobile } = useBreakpoint();

  const hideOnShortScreen = css`
    @media (max-height: 790px) {
      display: none;
    }
  `;

  return (
    <div className="relative flex-1 flex flex-col overflow-auto">
      {isPlaying && (
        <div className="text-h5 font-semibold whitespace-nowrap mb-4 md:hidden">
          {title}
        </div>
      )}

      <div className="h-full min-h-[15rem] max-h-101.5 overflow-hidden">
        {!isMobile && playerBgImage}
        <div className="md:absolute md:top-0 w-full">
          <div className="flex md:flex-col p-2 items-center">
            <div className="h-38 w-38 shrink-0 relative border-rad">
              {cover}
            </div>
            <div className="w-full min-w-0 md:mt-4 ml-8 md:ml-0">
              <div className="text-body2 text-center font-semibold pb-0.5 truncate overflow-hidden px-2 h-[1.375em]">
                {primaryPlayingTitle || title}
              </div>

              <div className="mt-2 text-overline text-center truncate overflow-hidden px-2 h-[1.125em]">
                {secondaryPlayingTitle}
              </div>
            </div>
          </div>

          <div className="mx-4 mt-8">
            {isPlaying || isPaused ? (
              <>
                <div
                  className={cls(
                    'hidden md:flex justify-between items-center',
                    hideOnShortScreen,
                  )}
                >
                  <Marquee
                    className="text-body1 font-semibold whitespace-nowrap leading-6"
                    gradient={false}
                  >
                    <span>{title}</span>
                  </Marquee>
                  {fullShareUrl && (
                    <Share
                      urlToShare={fullShareUrl}
                      shareBoxItemClassName="hover:bg-pink-gray"
                      shareIconClassName="text-gray-3 hover:text-white"
                    />
                  )}
                </div>

                {!!moderatorTags?.length && (
                  <div
                    className={cls(
                      'hidden md:flex md:flex-wrap items-start gap-2 mt-4 md:gap-2 mb-10',
                      hideOnShortScreen,
                    )}
                  >
                    {moderatorTags.map((tag) => (
                      <TagChip
                        className="hover:bg-pink-gray"
                        tag={tag}
                        key={tag.link.id}
                        onClick={() => {
                          onClose();
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              actionButton
            )}
          </div>
        </div>
      </div>

      {(isPlaying || isPaused) && (
        <>
          {countdowns}
          {actionButton}
        </>
      )}
    </div>
  );
};

export default Player;
