import cls from 'classnames';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import useOnScreen from '@/lib/hooks/use-on-screen';
import { setIsWelcomeAdVisible } from '@/store/slices/ads';

import Advertisement from '../../../advertisement';

interface WelcomeAdProps {
  className?: string;
  isMobile?: boolean;
}

const WelcomeAd = ({ isMobile = false, className = '' }: WelcomeAdProps) => {
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(setIsWelcomeAdVisible(isOnScreen));
    }
  }, [dispatch, isMobile, isOnScreen]);

  return (
    <div className={cls('relative !z-[30]', className)}>
      <Advertisement slot={isMobile ? 'MTA_1' : 'TA_1'} ref={ref} />
    </div>
  );
};

export default WelcomeAd;
