import cls from 'classnames';
import React, { FC } from 'react';

import CustomLink from '@/components/custom-link';
import ContentfulIcon from '@/components/icons/contentful-icon';
import { NavigationEntry } from '@/types/views/generic';

interface SocialNavigationProps {
  socialNavItems: NavigationEntry['socialNavItems'];
  wrapperClasses?: string;
  linkClasses?: string;
}

const SocialNavigation: FC<SocialNavigationProps> = ({
  socialNavItems,
  wrapperClasses,
  linkClasses,
}) => (
  <div className={cls('flex flex-wrap', wrapperClasses)}>
    {socialNavItems.map(({ link: { id, href, isExternal }, icon: { src } }) => (
      <CustomLink
        key={id}
        href={href}
        isExternal={isExternal}
        className={cls(
          'flex items-center justify-center w-10 h-10 mr-2 mb-2 rounded-full',
          linkClasses,
        )}
      >
        <ContentfulIcon src={src} />
      </CustomLink>
    ))}
  </div>
);

export default SocialNavigation;
