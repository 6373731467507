import { css } from '@emotion/css';
import cls from 'classnames';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import ReactFastMarquee from 'react-fast-marquee';

type MarqueeProps = React.ComponentProps<typeof ReactFastMarquee> & {
  children: ReactElement<HTMLElement>;
  separator?: string;
};

const separatorClass = (separator: string) => css`
  :after {
    content: '${separator}';
    display: inline-block;
    margin: 0 0.5em;
  }
`;

const isElementOverflowingChecker = (element: HTMLElement) =>
  element.offsetWidth < element.scrollWidth;

const Marquee: FC<MarqueeProps> = ({
  children,
  className,
  speed = 30,
  separator = '-',
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isElementOverflowing, setIsElementOverflowing] = useState(false);

  const setOverflowState = () => {
    if (
      containerRef.current &&
      isElementOverflowingChecker(containerRef.current)
    ) {
      setIsElementOverflowing(true);
    } else {
      setIsElementOverflowing(false);
    }
  };

  useEffect(() => {
    setOverflowState();

    window.addEventListener('resize', setOverflowState);

    return () => {
      window.removeEventListener('resize', setOverflowState);
    };
  }, [children]);

  return (
    <div className="relative overflow-hidden" ref={containerRef}>
      <span className={cls(className, `invisible`)}>{children}</span>

      <div className="absolute inset-0">
        <div className={className}>
          {isElementOverflowing ? (
            <ReactFastMarquee speed={speed} {...props}>
              {React.Children.map(children, (child) => (
                <div className={separatorClass(separator)}>{child}</div>
              ))}
            </ReactFastMarquee>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
