import { FC } from 'react';
import theme from 'tailwind/theme';

interface CrossIconProps {
  fill?: string;
  className?: string;
}

export const CrossIcon: FC<CrossIconProps> = ({
  fill = theme.colors.black.DEFAULT,
  className = '',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0076 6.14655C16.8125 5.95115 16.4962 5.95115 16.301 6.14655L12.0015 10.4521L7.69761 6.14818C7.50235 5.95291 7.18577 5.95291 6.9905 6.14818L6.14645 6.99224C5.95118 7.1875 5.95118 7.50408 6.14645 7.69934L10.4514 12.0043L6.15702 16.3046C5.96189 16.5 5.96189 16.8168 6.15702 17.0122L7.00049 17.8569C7.19562 18.0523 7.51198 18.0523 7.70711 17.8569L12.0026 13.5555L16.2975 17.8504C16.4928 18.0457 16.8093 18.0457 17.0046 17.8504L17.8487 17.0064C18.0439 16.8111 18.0439 16.4945 17.8487 16.2992L13.5526 12.0032L17.8511 7.69879C18.0462 7.50339 18.0462 7.18659 17.8511 6.99119L17.0076 6.14655Z"
      fill={fill}
    />
  </svg>
);

export default CrossIcon;
