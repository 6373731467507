interface SoundcloudProps extends React.ComponentProps<'iframe'> {
  title: string;
  src: string;
}

const Soundcloud = ({ title, src, ...iframeProps }: SoundcloudProps) => (
  <iframe
    {...iframeProps}
    title={title}
    src={`https://w.soundcloud.com/player/?url=${src}`}
  />
);

export default Soundcloud;
