import React, { FC, RefObject, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// import HeartIcon from '@/components/icons/heart-icon';
import PlaylistIcon from '@/components/icons/playlist-icon';
import useNavbar from '@/components/layout/use-navbar';
import Share from '@/components/share';
import VolumeButton from '@/components/volume-button';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import getChannelUrl from '@/lib/get-channel-url';
import { mapUrlToLink } from '@/middleware/mappers/link';
import {
  selectPlayerCurrentChannelWithGroup,
  selectPlayerIsPlaying,
} from '@/store/slices/player';

import { styles } from './styles';

const baseUrl = getBaseUrlIfBrowser();

interface IconBarProps {
  volumeButtonRef?: RefObject<HTMLDivElement>;
}

const IconBar: FC<IconBarProps> = ({ volumeButtonRef }) => {
  const [shareUrl, setShareUrl] = useState<string>(baseUrl);
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const currentChannelWithGroup = useSelector(
    selectPlayerCurrentChannelWithGroup,
  );
  const { isNavbarVisible } = useNavbar();

  const playlistUrl = currentChannelWithGroup
    ? getChannelUrl(
        currentChannelWithGroup.channel,
        currentChannelWithGroup.group,
        true,
      )
    : '';

  const playlistLink = playlistUrl ? mapUrlToLink(playlistUrl) : null;

  useEffect(() => {
    if (!currentChannelWithGroup) {
      setShareUrl(baseUrl);
    } else {
      const channelUrl = getChannelUrl(
        currentChannelWithGroup.channel,
        currentChannelWithGroup.group,
      );
      setShareUrl(`${baseUrl}${channelUrl}`);
    }
  }, [isPlaying, currentChannelWithGroup]);

  return (
    <div className={styles.wrapper}>
      <PlaylistIcon className={styles.icon} link={playlistLink} />

      <VolumeButton
        className={styles.volumeButton}
        innerRef={volumeButtonRef}
      />

      <Share
        urlToShare={shareUrl}
        utmInfix="player-"
        shareIconClassName="text-gray-2 mx-1"
        forceShareBoxHiding={isNavbarVisible}
      />
      {/* <HeartIcon className={styles.icon} /> */}
    </div>
  );
};

export default IconBar;
