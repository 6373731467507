import cls from 'classnames';
import { FC, RefObject } from 'react';

import ChannelSwitcherDesktop from '@/components/channel-switcher/desktop';

interface ChannelSwitcherDesktopOverlayProps {
  isChannelSwitcherOpen: boolean;
  onChannelSwitcherToggle: () => void;
  channelSwitchRef: RefObject<HTMLElement> | null;
}

const ChannelSwitcherDesktopOverlay: FC<ChannelSwitcherDesktopOverlayProps> = ({
  isChannelSwitcherOpen,
  onChannelSwitcherToggle,
  channelSwitchRef,
}) => (
  <div
    className={cls(
      'fixed z-40 pt-24 w-screen min-h-screen transform transition-transform duration-500',
      isChannelSwitcherOpen
        ? 'translate-y-0'
        : '-translate-y-full teair:translate-y-full tesn:translate-y-full',
    )}
  >
    <ChannelSwitcherDesktop
      ref={channelSwitchRef}
      isOpen={isChannelSwitcherOpen}
      onClose={onChannelSwitcherToggle}
    />
  </div>
);

export default ChannelSwitcherDesktopOverlay;
