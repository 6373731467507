import { css } from '@emotion/css';
import cls from 'classnames';

const boxShadow = css`
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.35);
`;

const styles = {
  wrapper: (isModal = true) =>
    cls(
      isModal &&
        `fixed inset-0 overflow-auto flex justify-center items-center z-60`,
    ),
  backdrop: (isAnimated: boolean) =>
    `absolute bg-black w-full h-full z-40 transition-opacity duration-300 ${
      isAnimated ? 'opacity-90' : 'opacity-0'
    }`,
  content: 'md:content-box',
  form: (isAnimated: boolean, isModal = true) =>
    cls(
      'bg-black-light md:rounded-2 px-5 py-12 md:p-6 lg:p-12',
      'tesn:bg-esn-secondary-dark tels:bg-els-blue-grey teair:bg-air-deepBlue',
      isModal &&
        `relative z-50 transition-zoomIn duration-200 transform ${boxShadow} ${
          isAnimated ? 'scale-100 opacity-100' : 'scale-75 opacity-0'
        }`,
    ),
  closeIcon:
    'absolute w-8 h-8 bg-white right-6 md:left-0 -top-10 flex justify-center items-center rounded-16 cursor-pointer',
};

export default styles;
