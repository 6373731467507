import { css } from '@emotion/css';
import cls from 'classnames';

const boxShadow = css`
  box-shadow: 0 8px 40px rgba(63, 63, 63, 0.2);
`;

const styles = {
  wrapper: (isNavOpen: boolean) =>
    cls(
      'z-70 flex flex-col max-h-screen items-center bg-black',
      isNavOpen
        ? 'fixed top-0 left-0 w-screen h-screen bg-black safari-100vh-hack'
        : `sticky ${boxShadow}`,
    ),
  contentContainer:
    'content-box flex flex-shrink-0 justify-between items-center xl:py-4 relative teone:mx-auto xl:teone:content-box',
  playerBarDesktopWrapper: 'flex-auto min-w-0 hidden md:block',
  iconsWrapper:
    'flex flex-grow flex-shrink-0 xl:flex-1 xl:flex-shrink-0 min-w-max justify-end items-center',
  navigation: 'overflow-y-auto md:w-full md:h-full',
};

export default styles;
