import cls from 'classnames';
import React, { FC } from 'react';

interface CountdownProgressBarProps {
  // values in ms
  duration: number;
  timeLeft: number;
  backgroundColor?: string;
  foregroundColor?: string;
}

const CountdownProgressBar: FC<CountdownProgressBarProps> = ({
  timeLeft,
  duration,
  backgroundColor = 'bg-gray-1',
  foregroundColor = 'bg-red-medium',
}) => {
  const timeLeftInSeconds = duration - timeLeft;

  const progressPercentage = (timeLeftInSeconds / duration) * 100;

  const renderDot = () => (
    <div
      className={cls(
        'absolute transform right-0 translate-x-1/2 top-1/2 -translate-y-1/2 rounded-full h-4 w-4',
        foregroundColor,
      )}
    />
  );

  return (
    <div className="relative flex items-center h-4">
      <div
        className={cls('absolute h-1', foregroundColor)}
        style={{
          width: `${progressPercentage}%`,
        }}
      >
        {!!timeLeft && renderDot()}
      </div>

      <div className={cls('w-full h-1', backgroundColor)} />
    </div>
  );
};

export default CountdownProgressBar;
