import React, { FC } from 'react';

import Icon from '../icon';
import CrossIcon from '../icons/cross-icon';
import usePreviewBar from './usePreviewBar';

const wrapperClass = `fixed z-40 w-full bottom-0 left-0
  px-4 py-2 grid grid-cols-3 items-center
  bg-red-medium text-white text-overline`;

const PreviewBar: FC = () => {
  const { isVisible, close, disablePreview } = usePreviewBar();

  if (!isVisible) {
    return null;
  }

  return (
    <div className={wrapperClass}>
      <div className="col-start-2 justify-self-center">
        <span>Preview mode enabled</span>
      </div>

      <div className="justify-self-end flex gap-4 items-center">
        <button
          className="border-2 border-solid border-white rounded-2 px-2 py-1"
          onClick={disablePreview}
        >
          Disable preview mode
        </button>

        <Icon
          isButton
          title="Hide the preview bar"
          element={<CrossIcon />}
          onClick={close}
        />
      </div>
    </div>
  );
};

export default PreviewBar;
