import React, { FC } from 'react';

import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import { Channel as ChannelT, ChannelGroupType } from '@/types/views/generic';

import Channel from './channel';
import { channelList as styles } from './styles';

interface ChannelListProps {
  type: ChannelGroupType;
  channels: ChannelT[];
  isEventTheme: boolean;
  onPlaylistIconClick: () => void;
}

const ChannelList: FC<ChannelListProps> = ({
  type,
  channels,
  isEventTheme,
  onPlaylistIconClick,
}) => {
  const variables = useVariables();

  return (
    <div className={styles.wrapper}>
      <strong className={styles.text}>
        {getVariablePlainText(variables[`channel-switcher-mobile-${type}`])}
      </strong>

      {channels.map((channel) => (
        <Channel
          key={channel.id}
          channel={channel}
          channelGroup={type}
          isEventTheme={isEventTheme}
          onPlaylistIconClick={onPlaylistIconClick}
        />
      ))}
    </div>
  );
};

export default ChannelList;
