import {
  ButtonEAIR,
  ButtonEDS,
  ButtonELS,
  ButtonESN,
  ButtonGame,
  ButtonMain,
} from '@/components/button/normal';
import {
  ButtonColor,
  ButtonColorEAIR,
  ButtonColorEDS,
  ButtonColorELS,
  ButtonColorESN,
  ButtonColorGame,
  PageTheme,
} from '@/types/views/generic';

type ButtonVariantColorsProps = {
  esn?: ButtonColorESN;
  eair?: ButtonColorEAIR;
  eds?: ButtonColorEDS;
  els?: ButtonColorELS;
  default?: ButtonColor;
  light?: ButtonColor;
  dark?: ButtonColor;
  eone?: ButtonColor;
  game?: ButtonColorGame;
  legal?: ButtonColor;
  'music-award'?: ButtonColor;
};

const getThemedButtonProps = (
  theme: PageTheme | null,
  variantColors: ButtonVariantColorsProps = {
    esn: 'mint',
    eair: 'coral',
    eds: 'pink',
    els: 'light-blue',
    game: 'black',
    default: 'red',
  },
): ButtonEAIR | ButtonESN | ButtonEDS | ButtonELS | ButtonGame | ButtonMain =>
  (theme === null || !variantColors[theme]
    ? {
        variant: 'default',
        color: variantColors.default,
      }
    : {
        variant: theme,
        color: variantColors[theme],
      }) as
    | ButtonEAIR
    | ButtonESN
    | ButtonEDS
    | ButtonELS
    | ButtonGame
    | ButtonMain;

export default getThemedButtonProps;
