import { css } from '@emotion/css';
import cls from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import getSvgIconBody from '@/lib/get-svg-icon-body';

interface ContentfulIconProps {
  src: string;
  alt?: string;
  className?: string;
}

const checkIfIsSvgIcon = (url: string): boolean => url.includes('.svg');

const svgStyles = css`
  > svg * {
    fill: currentColor;
    stroke: currentColor;
  }
`;

export const ContentfulIcon: FC<ContentfulIconProps> = ({
  src,
  alt = '',
  className = '',
}) => {
  const isSvgIcon = useMemo(() => checkIfIsSvgIcon(src), [src]);
  const [iconBody, setIconBody] = useState<string>('');

  const fetchAndUpdateSvgIconBody = useCallback(async () => {
    if (isSvgIcon) {
      const svgIconBody = await getSvgIconBody(src);
      setIconBody(svgIconBody);
    }
  }, [isSvgIcon, src]);

  useEffect(() => {
    fetchAndUpdateSvgIconBody();
  }, [fetchAndUpdateSvgIconBody]);

  if (isSvgIcon && iconBody) {
    return (
      <span
        className={cls(className, svgStyles)}
        dangerouslySetInnerHTML={{ __html: iconBody }}
      />
    );
  }

  return <img src={src} alt={alt} className={className} />;
};

export default ContentfulIcon;
