import { FC } from 'react';
import { useSelector } from 'react-redux';

import RichTextRenderer from '@/components/rich-text-renderer';
import { logout, register } from '@/lib/auth';
import useVariables from '@/lib/hooks/use-variables';
import { selectIsUserLoggedIn } from '@/store/slices/user';

import LoginButton from '../login-button';
import styles from './styles';

const AuthButtons: FC = () => {
  const variables = useVariables();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  return (
    <div className={styles.authButtons}>
      <LoginButton />

      <span className="ml-2">oder</span>

      {isUserLoggedIn ? (
        <button className={styles.underlinedButton} onClick={logout}>
          {<RichTextRenderer body={variables['nav-logout-button']} />}
        </button>
      ) : (
        <button className={styles.underlinedButton} onClick={register}>
          {<RichTextRenderer body={variables['nav-register-button']} />}
        </button>
      )}
    </div>
  );
};

export default AuthButtons;
