import { FC, MouseEvent } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ImageAsset, TextLink } from '@/types/views/generic';

import styles from './styles';

interface CardNavItemProps {
  link: TextLink;
  cardImg: ImageAsset;
  onClick?: (e: MouseEvent) => void;
}

const CardNavItem: FC<CardNavItemProps> = ({ link, cardImg, onClick }) => (
  <CustomLink {...link} onClick={onClick}>
    <figure>
      <div className={styles.cardImgWrapper}>
        <Image
          src={cardImg.src}
          alt={cardImg.description}
          className={styles.cardImg}
        />

        <div className={styles.gradient} />
      </div>

      <figcaption className={styles.cardImgCaption}>{link.text}</figcaption>
    </figure>
  </CustomLink>
);

export default CardNavItem;
