import renderComponent, { ComponentsMap } from '@/lib/render-component';

import Embed from '../embed';
import LotteryFormTeaserSection from '../sections/lottery-form-teaser';
import EmbeddedImage from './embedded-image';
import EmbeddedLink from './embedded-link';
import VideoInline from './video';

const Components: ComponentsMap = {
  link: EmbeddedLink,
  image: EmbeddedImage,
  video: VideoInline,
  embed: Embed,
  forms: LotteryFormTeaserSection,
};

const renderEmbeddedEntry = renderComponent(Components);

export default renderEmbeddedEntry;
