import cls from 'classnames';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import { FC } from 'react';

import LotteryFormTeaser from '@/components/lottery-form/teaser';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';
import {
  EntryComponentProps,
  LotteryFormTeaser as LotteryFormTeaserT,
} from '@/types/views/generic';

const LotteryFormTeaserSection: FC<EntryComponentProps<LotteryFormTeaserT>> = ({
  teaserTitle,
  text,
  tag,
  ctaText,
  deadline,
  lottery,
  layout,
}) => {
  const variables = useVariables();
  const deadlinePreText = getVariablePlainText(
    variables['form-deadline-pre-text'],
  );

  const formatDeadline = deadline
    ? format(new Date(deadline), 'dd. MMMM yyyy', { locale: de })
    : null;

  return (
    <LotteryFormTeaser
      teaserTitle={teaserTitle}
      text={text}
      tag={tag}
      ctaText={ctaText}
      {...(formatDeadline && {
        deadlineText: `${deadlinePreText} ${formatDeadline}`,
      })}
      isActiveInTimespan={Boolean(lottery.active)}
      lottery={lottery}
      className={cls(
        layout === 'page' && 'content-box',
        layout === 'rich-text' && 'text-box text-white',
      )}
    />
  );
};

export default LotteryFormTeaserSection;
